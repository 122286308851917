import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { handleCommonInputError } from "../util/commonValidation";
import { commonStates, hmsProgressBarUpdate } from "../util/share";
import { currentActiveStep } from "./step2";
import { ServiceInspection } from "./step5";

// Interface for custom select elements
interface CustomSelectElement extends HTMLDivElement {
    selectButton: HTMLButtonElement;
}


let suggestedAddress: any = ''
let flagShowPopup = false;
let address1Details1: any = null;
let allAddressList: any = []
const DISCOVER_URL = 'https://discover.search.hereapi.com/v1/discover?q=';


export class HMSStep4 {
    
    private errorMessage: { serviceAddressInput: string; } | undefined;
    private serviceAddressInput: HTMLInputElement;
    private serviceAddress2Input: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private selectCity: HTMLInputElement;
    private selectState: HTMLInputElement;
    private yearBuiltInput: HTMLInputElement;
    private sqrftInput: HTMLInputElement;
    private foundationTypeInput: HTMLInputElement;
    private bedRoomInput: HTMLInputElement;
    private kitchenInput: HTMLInputElement;
    private poolInput: HTMLInputElement;
    private onUtitlityInput: HTMLInputElement;
    private occupyInput: HTMLInputElement;
    private howAboutInput: HTMLInputElement;
    private showButton: Boolean;
    private bathRoomInput: HTMLInputElement;
    private form: any;
    nextSibling: any;
    classList: any;
    staticData: boolean;


    constructor() {

        this.staticData = false;
        // this.currentFormActive();
        this.showButton = false
        this.serviceAddressInput = document.getElementById('address-1') as HTMLInputElement;
        this.serviceAddress2Input = document.getElementById('address-2') as HTMLInputElement;
        this.selectCity = document.getElementById('select-city') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('select-zip') as HTMLInputElement;
        this.selectState = document.getElementById('select-state-province') as HTMLInputElement;
        this.yearBuiltInput = document.getElementById('input-year-built') as HTMLInputElement;
        this.sqrftInput = document.getElementById('input-sqrft') as HTMLInputElement;
        this.foundationTypeInput = document.getElementById('select-foundation') as HTMLInputElement;
        this.bedRoomInput = document.getElementById('input-bedroom') as HTMLInputElement;
        this.bathRoomInput = document.getElementById('input-bathroom') as HTMLInputElement;
        this.kitchenInput = document.getElementById('input-kitchen') as HTMLInputElement;
        this.poolInput = document.getElementById('yes-pool') as HTMLInputElement;
        this.onUtitlityInput = document.getElementById('on-utility') as HTMLInputElement;
        this.occupyInput = document.getElementById('yes-occupy') as HTMLInputElement;
        this.howAboutInput = document.getElementById('how-about-hms') as HTMLInputElement;

        
        if (this.serviceAddressInput) {
            this.serviceAddressInput.addEventListener('input', this.setAutoSuggested.bind(this));
        }


        this.commonErrorValidation();

        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");

        const saveBtn: any = document.querySelector('.property-address-modal a.btn')
        saveBtn?.addEventListener('click', () => {

            flagShowPopup = false
            const btn: any = document.getElementById('suggested-address-radio')
            const entAddRadio: any = document.getElementById('entered-address-radio');
            if (entAddRadio?.checked) {
                sessionStorage.setItem("suggestion", "false");
                this.getAutoSuggested();
            }
            console.log("saveBtn", btn?.checked)
            if (btn?.checked && suggestedAddress?.address?.label) {
                this.serviceAddressInput.value = suggestedAddress?.address?.label
                this.hadleaddressReate(this.serviceAddressInput.value);
                sessionStorage.setItem("suggestion", "true");
            }
        })

        this.form = document.querySelectorAll('#property-step .btn-next');

        const goBack = document.querySelectorAll('#property-step .btn-cancel');

        if (this.form && this.form[0]) {
            this.form[0].addEventListener('click', this.handleSubmit.bind(this));
        }
        if (goBack && goBack[0]) {
            goBack[0].addEventListener('click', this.handleGoBack.bind(this));
        }

        // hmsProgressBarUpdate('50%', 2);
        this.setupZip();
        (async () => {
            try {
                await Promise.all([
                    this.setHowOknow(),
                    this.fetchAllState()
                ]);
                let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as string); 
                // if(!hmsBookingData?.HmsRoleInfo?.HmsPropertyDetail){
                    this.initializeCustomSelect();
                    this.editProperty();
                // }
            } catch (error) {
                // Handle any errors that may occur during promise resolution
                console.error("Error:", error);
            }
        })();
    }

    commonErrorValidation(){
         // Define inputs along with their corresponding regex
         const inputs = [
            { element: this.serviceAddressInput, regex: REGEX.sendAddress1, errorMessage: 'Invalid address format.' },
            { element: this.serviceAddress2Input, regex: REGEX.sendAddress2, errorMessage: 'Invalid address2 format.' },
            { element: this.selectCity, regex: REGEX.sendName, errorMessage: 'Enter Valid City' },
            { element: this.zipCodeInput, regex: REGEX.sendZip, errorMessage: 'Invalid zip code format.' },
            { element: this.yearBuiltInput, regex: REGEX.sendYear, errorMessage: 'Must be in the last 250 years in format YYYY' },
            { element: this.sqrftInput, regex: REGEX.allow3Digit, errorMessage: 'Please enter a number between 500 - 99999' },
            { element: this.bedRoomInput, regex: REGEX.allow1to99, errorMessage: 'Please enter a number between 1 - 99' },
            { element: this.bathRoomInput, regex: REGEX.allow1to99, errorMessage: 'Please enter a number between 1 - 99' },
            { element: this.kitchenInput, regex: REGEX.allow1to9, errorMessage: 'Please enter a number between 1 - 9' },
        ];
        
        // Attach event listeners to each input
        inputs.forEach(({ element, regex, errorMessage }) => {
            if (element) {
                element.addEventListener('input', () => handleCommonInputError(element, `${element.id}-modal-error-msg`, regex, errorMessage));
            }
        });
        

        const selectInputs = [
  
            { element: this.selectState, regex: REGEX.sendName, errorMessage: 'Please select State' },
            { element: this.foundationTypeInput, regex: REGEX.sendName, errorMessage: 'Please Select Foundation Type.' },
            { element: this.howAboutInput, regex: REGEX.sendName, errorMessage: 'Please Select' },
        ];
        
        // Attach event listeners to each select element
        selectInputs.forEach(({ element, regex, errorMessage }) => {
            if (element) {
                element.addEventListener('change', () => handleCommonInputError(element, `${element.id}-modal-error-msg`, regex, errorMessage));
            }
        });
    }


    handleGoBack (){
        const serviceStep = document.getElementById('inspection-step');
        currentActiveStep(serviceStep);
        hmsProgressBarUpdate('25%', 1);
        window.scrollTo(0,0);
    }


    setupZip(){
        let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as string); 
        if(this.zipCodeInput){
            if(hmsBookingData?.zipcode)
                this.zipCodeInput.value = hmsBookingData?.zipcode
            else{
                const localizedZipFromSession = sessionStorage.getItem("sAddressParam")as string;
                this.zipCodeInput.value = (localizedZipFromSession) ? localizedZipFromSession:"";
            }
        }
        
    }


    nextStep5() {
        const serviceStep = document.getElementById('service-step');
        currentActiveStep(serviceStep);
        const step5 = sessionStorage.getItem("hms_s5");
        if(!step5)
            new ServiceInspection();

    }
    handleSubmit() {

        if (this.validateForm()) {

            let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as string);
            const { serviceAddressInput, serviceAddress2Input, selectCity, zipCodeInput, selectState, yearBuiltInput, sqrftInput, foundationTypeInput, bedRoomInput, bathRoomInput, kitchenInput, poolInput, onUtitlityInput, occupyInput, howAboutInput } = this;

            let propertyPayload: any = hmsBookingData;

            const hmsRootPropertyData =
            {
                "Address": serviceAddressInput?.value,
                "Address2": serviceAddress2Input?.value || null,
                "City": selectCity?.value,
                "State": selectState?.value,
                "Country": sessionStorage.getItem("countryName"),
            };

            propertyPayload = {...propertyPayload, ...hmsRootPropertyData};

            let HmsPropertyDetail = {
                "BuiltYear": yearBuiltInput?.value,
                "SquareFeet": parseInt(sqrftInput?.value),
                "TypeOfFoundation": foundationTypeInput?.value,
                "NumberOfBedrooms": parseInt(bedRoomInput?.value),
                "NumberOfBathrooms": parseInt(bathRoomInput?.value),
                "NumberOfKitchens": parseInt(kitchenInput?.value),
                "IsPoolAvailable": poolInput?.checked,
                "UtilitiesOn": onUtitlityInput?.checked,
                "HearAboutHouseMaster": howAboutInput?.value,
                "explainOther": "",
                "isPropertyOccupied": occupyInput?.checked
            }

          
            if (!hmsBookingData?.HmsRoleInfo) {
                propertyPayload = {
                    "HmsRoleInfo": {
                        "HmsPropertyDetail": HmsPropertyDetail
                    },...hmsBookingData
                };
            } else {
              
                propertyPayload.HmsRoleInfo = {
                    ...hmsBookingData.HmsRoleInfo ,
                    "HmsPropertyDetail": {
                        ...HmsPropertyDetail
                    }
                }
            }

            hmsBookingData = propertyPayload;
            console.log(propertyPayload, "propertyPayload");
            sessionStorage.setItem('hms_booking', JSON.stringify(hmsBookingData));

            if (serviceAddressInput.value.trim() == suggestedAddress?.address?.label){
                flagShowPopup = false
            }

            if (flagShowPopup && suggestedAddress?.address?.label) {
                const callPopupModal = document.getElementById('address-modal')

                const addressData = suggestedAddress?.address?.label

                callPopupModal?.click()
                const enteredaddres = document.querySelector('[for=entered-address-radio]') as HTMLElement;
                if(enteredaddres) 
                    enteredaddres.innerHTML = this.serviceAddressInput.value;
                const suggestedaddress = document.querySelector('[for=suggested-address-radio]') as HTMLElement;
                if(suggestedaddress)
                    suggestedaddress.innerHTML = addressData;
    
            } else {
                this.nextStep5();
            }


        }
    }

    public validateForm(): boolean {

        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: Record<string, string> = {};

        const yesPool = document.getElementById('yes-pool') as HTMLInputElement;
        const noPool = document.getElementById('no-pool') as HTMLInputElement;

        if(!yesPool.checked && !noPool.checked) {
            const errorModal = document.getElementById('yes-pool-modal-error-msg') as HTMLElement;
            errorModal.innerText = errorModal.innerText || "Please select";
            errorModal.classList.remove('hidden');
        }

        const onUtility = document.getElementById('on-utility') as HTMLInputElement;
        const offUtility = document.getElementById('off-utility') as HTMLInputElement;

        if(!onUtility.checked && !offUtility.checked) {
            const errorModal = document.getElementById('on-utility-modal-error-msg') as HTMLElement;
            errorModal.innerText = errorModal.innerText || "Please select";
            errorModal.classList.remove('hidden');
        }

        const yesOccupy = document.getElementById('yes-occupy') as HTMLInputElement;
        const noOccupy = document.getElementById('no-occupy') as HTMLInputElement;

        if(!yesOccupy.checked && !noOccupy.checked) {
            const errorModal = document.getElementById('yes-occupy-modal-error-msg') as HTMLElement;
            errorModal.innerText = errorModal.innerText || "Please select";
            errorModal.classList.remove('hidden');
        }

        const inputs = [
            { input: this.serviceAddressInput, id: this.serviceAddressInput?.id, regex: REGEX.sendAddress1, errorMessage: 'Invalid address format.' },
            { input: this.serviceAddress2Input, id: this.serviceAddress2Input?.id, regex: REGEX.sendAddress2, errorMessage: 'Invalid address2 format.' },
            { input: this.selectCity, id: this.selectCity?.id, regex: REGEX.sendName, errorMessage: 'Enter Valid City' },
            { input: this.selectState, id: this.selectState?.id, regex: REGEX.matchAny, errorMessage: 'Please select State' },
            { input: this.zipCodeInput, id: this.zipCodeInput?.id, regex: REGEX.sendZip, errorMessage: 'Invalid zip code format.' },
            { input: this.yearBuiltInput, id: this.yearBuiltInput?.id, regex: REGEX.sendYear, errorMessage: 'Must be in the last 250 years in format YYYY' },
            { input: this.sqrftInput, id: this.sqrftInput?.id, regex: REGEX.allow3Digit, errorMessage: 'Please enter a number between 500 - 99999' },
            { input: this.foundationTypeInput, id: this.foundationTypeInput?.id, regex: REGEX.matchAny, errorMessage: 'Please Select Foundation Type.' },
            { input: this.bedRoomInput, id: this.bedRoomInput?.id, regex: REGEX.allow1to99, errorMessage: 'Please enter a number between 1 - 99' },
            { input: this.bathRoomInput, id: this.bathRoomInput?.id, regex: REGEX.allow1to99, errorMessage: 'Please enter a number between 1 - 99' },
            { input: this.kitchenInput, id: this.kitchenInput?.id, regex: REGEX.allow1to9, errorMessage: 'Please enter a number between 1 - 9' },
            // { input: this.poolInput, id: this.poolInput?.id, regex: REGEX.sendNumber, errorMessage: 'Enter Enter valid Number' },
            // { input: this.onUtitlityInput, id: this.onUtitlityInput?.id, regex: REGEX.sendName, errorMessage: 'Enter Valid Sqrfeet' },  
            // { input: this.occupyInput, id: this.occupyInput?.id, regex: REGEX.sendName, errorMessage: 'Enter Valid Sqrfeet' },
            { input: this.howAboutInput, id: this.howAboutInput?.id, regex: REGEX.matchAny, errorMessage: 'Please Select' },
        ];


        inputs.forEach(({ input, id, regex, errorMessage }) => {
            let errorMessage2 = errorMessage;
            errorMessage = document.getElementById(`${id}-modal-error-msg`)?.innerText ?? errorMessage;

            if (input.required && input.value === '') {
                errors[id] = errorMessage;
            } else if (input.value !== '' && regex && !regex.test(input.value)) {
                errors[id] = errorMessage2;
            } else if (input.id === 'input-year-built'){
                // Get the current year
                const currentYear:any = input.value;

                // Validate the year
                const isValidYear = currentYear >= (new Date().getFullYear() - 250) && currentYear <= new Date().getFullYear();
                if(!isValidYear){
                    errors[id] = errorMessage2;
                }


            }

            //  else if (input.value !== '' && input.isPhone && input.value.length < this.OWNER_NUMBER_LENGTH) {
            //     const fieldError = document.getElementById(`${id}-error-msg`);
            //     errors[id] = errorMessage2;
            //     if (fieldError) {
            //         fieldError.innerHTML = errorMessage;
            //         fieldError.classList.remove('hidden');
            //     }
            // }
        });

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }

        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-modal-error-msg`);
            if (field && field.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        return Object.keys(errors).length === 0;

    }

    hadleaddressReate(matchingSuggestion: any) {

        address1Details1 = '';
        const matchedAddress = allAddressList?.find((address: any) => address?.title.trim() === matchingSuggestion.trim());
        sessionStorage.setItem("stateCode", matchedAddress?.address?.stateCode);
        sessionStorage.setItem("countryName", matchedAddress?.address?.countryName)
        if (matchedAddress) {
            if (matchedAddress?.address?.houseNumber && matchedAddress?.address?.street) {
                address1Details1 = matchedAddress?.address?.houseNumber + " " + matchedAddress?.address?.street;
            } else {
                let hasSteert: any = Object.keys(matchedAddress?.address).includes('street');
                let hasHousenum: any = Object.keys(matchedAddress?.address).includes('houseNumber');
                if (hasSteert === true && hasHousenum === false) {
                    address1Details1 = matchedAddress?.address?.street;
                }
                if (hasSteert === false && hasHousenum === true) {
                    address1Details1 = matchedAddress?.address?.houseNumber;
                }
            }
        }
    }

    private getAutoSuggested = () => {
        let isFlag = false
        const input = document.querySelectorAll('.input-label-wrap #select-zip')[0];
        if (input && input.id == 'select-zip') {
            isFlag = true
        }

        const request = {
            method: 'GET',
            url: `${DISCOVER_URL}${(isFlag && this.zipCodeInput.value) ? `${this.zipCodeInput.value}` : (document.getElementById('address-1') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
        }
        apiRequest(request)
            .then((result: any) => {
                let manualCityVal = result?.items[0]?.address?.city;
                let manualStateVal = result?.items[0]?.address?.state;
                sessionStorage.setItem("manualCity", manualCityVal);
                sessionStorage.setItem("manualState", manualStateVal);
            })
            .catch((err) => {
            });

    }

    private setAutoSuggested = async (e: any) => {

        e.preventDefault()
        let isFlag = false
        const input: any = document.querySelectorAll('.input-label-wrap #select-zip')[0]
        sessionStorage.setItem("suggestion", "false");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        if (input && input.id == 'select-zip') {
            isFlag = true
        }

        const request = {
            method: 'GET',
            url: `${DISCOVER_URL}${(isFlag && this.zipCodeInput.value) ? `${(document.getElementById('address-1') as HTMLInputElement).value}, ${this.zipCodeInput.value}` : (document.getElementById('address-1') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
        }
        apiRequest(request)
            .then((result: any) => {
                allAddressList = result?.items
                suggestedAddress = result?.items[0]
                this.setDatat(result?.items.map((item: any) => item))
            })
            .catch((err) => {
            });
    }

    private setCity(city: string){
        if(this.selectCity){
            this.selectCity.value = city;
            this.hideErrorMessage(this.selectCity?.id);

        }
    }

    private async setState(stateCode:string,state:string){

    //   const stateButton = document.querySelector("#select-state-province + .select-selected, #select-state-province + .custom-select .select-selected") as HTMLButtonElement;

      let selectProvince = document.querySelector("#select-state-province") as HTMLElement | any;
      let stateButton = selectProvince?.parentElement.querySelector(".select-selected") as HTMLButtonElement;
      const stateSelect = document.querySelector("#select-state-province") as HTMLButtonElement;
      const fetchAllStates:any[] | undefined  = await this.fetchAllState();
      const findStateIndex :any = fetchAllStates?.findIndex(st => st.stateCode?.toLowerCase() === stateCode?.toLowerCase());
     
      let stateName = state;

      if(findStateIndex !=-1 && fetchAllStates && fetchAllStates.length > 0) {
        stateName = fetchAllStates[findStateIndex]?.stateName;
      }

      if(stateButton && (findStateIndex==0 || findStateIndex) && stateSelect){

        stateSelect.value = stateCode;
        stateButton.innerText = stateName;
        stateButton.style.backgroundColor = "var(--primary-500)";
        stateButton.style.color = "var(--white)";
        stateButton.style.whiteSpace = "nowrap";
        stateButton.style.overflow = "hidden";
        stateButton.style.borderRight = "40px solid var(--primary-500)";
        this.hideErrorMessage('select-state-province');
      }

    }

    private setDatat = (suggestions: any) => {
        try {

            const inputElement: any = document.getElementById("address-1") as HTMLInputElement;
            const inputValue = inputElement.value.toLowerCase();
            const suggestionContainer: any = document.createElement("ul") as HTMLElement
            suggestionContainer.className = "suggestion-container";

            // Clear previous suggestions
            while (inputElement.nextSibling && inputElement.nextSibling.className === "suggestion-container") {
                inputElement.parentNode.removeChild(inputElement.nextSibling);
            }

            if (inputValue) {

                suggestions.forEach((matchingSuggestion: any) => {
                    let clickedFlag: boolean = false;
                    const suggestionItem = document.createElement("li");
                    suggestionItem.className = "suggestion-item";
                    suggestionItem.textContent = matchingSuggestion?.title;
                    suggestionItem.setAttribute('data-city', matchingSuggestion?.address.city?? '');
                    suggestionItem.setAttribute('data-country-name', matchingSuggestion?.address?.countryName ?? '');
                    suggestionItem.setAttribute('data-state-code', matchingSuggestion?.address?.stateCode ?? '');
                    suggestionItem.setAttribute('data-state-name', matchingSuggestion?.address?.state ?? '');

                    suggestionItem.addEventListener("click", () => {
                        clickedFlag = true;
                        flagShowPopup = false
                        sessionStorage.setItem("suggestion", "true");
                        inputElement.value = matchingSuggestion?.title;
                        suggestionContainer.parentNode.removeChild(suggestionContainer);
                        this.setZipCode()
                        // concatinating address field with houseNumber + streetName to be passed in booking api //230654
                        this.hadleaddressReate(matchingSuggestion?.title);
                        const city  = suggestionItem.getAttribute('data-city') ?? '';
                        const countryName  = suggestionItem.getAttribute('data-country-name');
                        const stateCode  = suggestionItem.getAttribute('data-state-code') ?? '';
                        const state  = suggestionItem.getAttribute('data-state-name') ?? '';

                        this.setCity(city);
                        this.setState(stateCode,state);


                    });
                    // suggestedAddress.address.label = suggestions[0] ?? ''
                    if (!clickedFlag) {
                        flagShowPopup = true;
                    }

                    suggestionContainer.appendChild(suggestionItem);
                });

                if (suggestions.length > 0) {
                    inputElement.parentNode.insertBefore(suggestionContainer, inputElement.nextSibling);
                }
            }

        } catch (error) {
            console.log("error", error)
        }

    }

    setZipCode = () => {
        let isFlag = true
        flagShowPopup = false
        const input: any = document.querySelectorAll('.input-label-wrap #select-zip')[0];
        if (input && input.id == 'select-zip') {
            isFlag = false
        }

        const address = this.serviceAddressInput.value
        const item = allAddressList.filter((item: any) => item.title == address)[0]
        const val = String(item?.address?.postalCode).split('-')[0]
        if (val && isFlag)
            this.zipCodeInput.value = val

        setTimeout(() => {
            this.addreddErrorRemove.call(this)
        }, 300)
    }

    addreddErrorRemove() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }

        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`address-1-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false

        }
        else {
            this.showButton = true
            this.hideError('address-1')
            this.hideError('select-zip')
        }

    }

    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-modal-error-msg`);

        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')
    }

    async setHowOknow() {
        return new Promise<void>((resolve, reject) => {
            let dynamic_url = `${apiConfig.howToKnow}?apikey=${process.env.JS_API_KEY}`;
            const franchiseId = sessionStorage.getItem('franchiseId');
            const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value?.toLocaleLowerCase();

            const request = {
                method: 'GET',
                url: dynamic_url,
            };

            const selectHowOnow = document.getElementById('how-about-hms') as HTMLSelectElement;
            if(selectHowOnow){

                apiRequest(request)
                    .then((response: any) => {
                        const options = response;
                        // Clear existing options
                        selectHowOnow.innerHTML = '';
                        // Create and append new <option> elements
                        const defaultOption = document.createElement('option');
                        defaultOption.value = ''; // Set the value attribute as needed
                        defaultOption.textContent = 'Select a Referral Method'; // Set the option text as needed
                        let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as string);

                        if(hmsBookingData?.ReferralType){
                            defaultOption.value = hmsBookingData?.ReferralType; 
                            defaultOption.textContent = hmsBookingData?.ReferralType; 
                        }
                        selectHowOnow.appendChild(defaultOption);

                        options.forEach((optionData: any) => {
                            const option = document.createElement('option');
                            option.value = optionData.leadSourceName; // Set the value attribute as needed
                            option.textContent = optionData.leadSourceName; // Set the option text as needed
                            option.setAttribute('data-sync-generic-lead-source-id', optionData.syncGenericLeadSourceId);
                            option.setAttribute('data-referral-type-id', optionData.referralTypeId);
                            selectHowOnow.appendChild(option);
                        });

                        resolve(); // Resolve the promise after successful completion
                    })
                    .catch((error) => {
                        reject(error); // Reject the promise if there's an error
                    });
            }
        });
    }


    private createCommonState(finalResult: any) {

        const selectState = document.getElementById('select-state-province') as HTMLSelectElement;
        if(selectState){
            while (selectState?.firstChild) {
                selectState.removeChild(selectState.firstChild);
            }
            const option = document.createElement("option");
            option.text = 'State/Province';
            option.value = '';
            selectState.appendChild(option);
            finalResult?.forEach((item: any) => {
                const option = document.createElement("option");
                option.text = item.stateName;
                option.value = item.stateCode;
                selectState.appendChild(option);
            });
        }
        
    }

    async fetchAllState() {
        try {
            const state_us: any[] = await commonStates('US');
            const state_ca: any[] = await commonStates('CA');
            let finalResult = [...state_us, ...state_ca];
            finalResult.sort((a, b) => {
                if (a.stateName !== b.stateName) {
                    return a.stateName < b.stateName ? -1 : 1;
                } else {
                    return 0;
                }
            });
            this.createCommonState(finalResult);
            return finalResult;
        }
        catch (error) {
            console.error('Error in fetching state API data:', error);
        }
    }



    // Function to create custom select elements
    createCustomSelect() {
        const customSelects: NodeListOf<CustomSelectElement> = document.querySelectorAll("#property-step .custom-select");

        customSelects.forEach(select => {
            const selectElement: HTMLSelectElement | any = select.querySelector("select");
            const selectOptions: NodeListOf<HTMLOptionElement> = selectElement.querySelectorAll("option");
            const selectedOption: string = selectOptions[selectElement.selectedIndex]?.innerHTML ?? '';
            const selectButton: HTMLButtonElement = document.createElement("button");
            selectButton.type = "button";
            selectButton.classList.add("select-selected");
            selectButton.innerHTML = selectedOption;
            select.appendChild(selectButton);

            const optionsContainer: HTMLDivElement = document.createElement("div");
            optionsContainer.classList.add("select-items", "select-hide", "slim-scroll");
            selectOptions.forEach(option => {
                let self = this;
                if (option.index !== 0) {
                    const optionButton: HTMLButtonElement = document.createElement("button");
                    optionButton.type = "button";
                    optionButton.innerHTML = option.innerHTML;
                    optionButton.value = option.value;
                 
                    if(select.querySelector("#how-about-hms")){
                        
                        if(option?.getAttribute('data-referral-type-id')){
                            optionButton.setAttribute('data-referral-type-id', option?.getAttribute('data-referral-type-id') as any);
                        }

                        if(option?.getAttribute('data-sync-generic-lead-source-id')){
                        optionButton.setAttribute('data-sync-generic-lead-source-id', option?.getAttribute('data-sync-generic-lead-source-id') as any);
                        }       
                    }
           

                    optionButton.addEventListener("click", function (e: any) {
                        const selectedText: string | any = e.target?.innerHTML;
                        selectButton.innerHTML = selectedText;
                        selectElement.value = e.target?.value;

                        if(select.querySelector("#how-about-hms")){
                         let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as string);

                         const selectedDataReferalId = e.target?.getAttribute('data-referral-type-id');
                         const selectedValue = e.target.value;
                 
                         if(selectedDataReferalId && selectedDataReferalId !=null){
                            hmsBookingData.ReferralTypeId = selectedDataReferalId;
                         }
                         if(selectedValue){
                            hmsBookingData.ReferralType = selectedValue;
                         
                        }
                        const selectedLeadSourceId = e.target?.getAttribute('data-sync-generic-lead-source-id');
                        
                        
                        let brandData: string | any= localStorage.getItem("brandDetails");

                        if(brandData !=='undefined' && brandData !== undefined  && brandData !== null){
                            brandData = JSON.parse(brandData) as any;

                           const selectedLeadSourceId = e.target?.getAttribute('data-sync-generic-lead-source-id');
     
                           if(brandData && brandData?.send_leadsource_id && selectedLeadSourceId ){
                               hmsBookingData.LeadSourceID = selectedLeadSourceId;
                           }
                       
                        }
                        sessionStorage.setItem('hms_booking', JSON.stringify(hmsBookingData));
                        }
                        self.closeAllSelect(selectButton);
                        if(select.querySelector("#select-state-province")){
                            self.hideErrorMessage('select-state-province');
                        }
                        if(select.querySelector("#select-foundation")){
                            self.hideErrorMessage('select-foundation');
                        }
                        if(select.querySelector("#how-about-hms")){
                            self.hideErrorMessage('how-about-hms');
                        }
                    });
                    optionsContainer.appendChild(optionButton);
                }
            });
            select.appendChild(optionsContainer);
            let self = this;
            selectButton.addEventListener("click", function (e) {
                e.stopPropagation();
                self.closeAllSelect(this);
                const nextSibling = this.nextSibling as HTMLDivElement | null;
                if (nextSibling) {
                    nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                }
                document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape') {
                        self.nextSibling.classList.add("select-hide");
                        self.classList.remove("select-arrow-active");
                    }
                });

                selectButton.style.backgroundColor = "var(--primary-500)";
                selectButton.style.color = "var(--white)";
                selectButton.style.whiteSpace = "nowrap";
                selectButton.style.overflow = "hidden";
                selectButton.style.borderRight = "40px solid var(--primary-500)";

            });

            // Additional functionality for the first select item
            //   if (select === customSelects[0]) {
            //     selectButton.style.backgroundColor = "var(--primary-500)";
            //     selectButton.style.color = "var(--white)";
            //     const secondSelectButton = document.querySelector('.custom-select:nth-child(2) .select-selected') as HTMLButtonElement;
            //     secondSelectButton.disabled = true;

            //     optionsContainer.onclick = function() {
            //       selectButton.style.backgroundColor = "var(--primary-500)";
            //       selectButton.style.color = "var(--white)";
            //     };
            //   }
        });
    }

    // Function to close all select boxes
    closeAllSelect(except: HTMLButtonElement | any) {
        const allSelectItems: NodeListOf<HTMLDivElement> = document.querySelectorAll("#property-step .select-items.slim-scroll");
        const allSelectButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll("#property-step .select-selected");
        allSelectItems.forEach((item, index) => {
            if (except !== allSelectButtons[index]) {
                item.classList.add("select-hide");
                allSelectButtons[index]?.classList?.remove("select-arrow-active");
            }
        });
    }

    // Main function to initialize custom select elements
    initializeCustomSelect() {
        let elements = document.querySelectorAll('#property-step .select-selected') as NodeList;
            elements.forEach(function(element) {
                if(element.parentNode){
                    element.parentNode.removeChild(element);
                }
            });

        this.createCustomSelect();

        // Event listener to close all select boxes when clicking outside
        let self = this;
        document.addEventListener("click", function () {
            self.closeAllSelect(null);
        });
    }

    hideErrorMessage(id: any) {
        const errorElement = document.getElementById(`${id}-modal-error-msg`);
        if(errorElement){
            errorElement?.classList.add('hidden');
        }
    }

    editProperty() {
        let hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') || '{}');
        const { serviceAddressInput, serviceAddress2Input, selectCity, zipCodeInput, selectState, yearBuiltInput, sqrftInput, foundationTypeInput, bedRoomInput, bathRoomInput, kitchenInput, poolInput, onUtitlityInput, occupyInput, howAboutInput } = this;
    
        if (serviceAddressInput && hmsBookingData.Address) {
            serviceAddressInput.value = hmsBookingData.Address;
        }
        if (serviceAddress2Input && hmsBookingData.Address2) {
            serviceAddress2Input.value = hmsBookingData.Address2;
        }
        if (selectCity && hmsBookingData.City) {
            selectCity.value = hmsBookingData.City;
        }
        if (zipCodeInput && hmsBookingData.ZipCode) {
            zipCodeInput.value = hmsBookingData.zipcode;
        }
        if (selectState && hmsBookingData.State) {
            selectState.value = hmsBookingData.State;
            this.setState(hmsBookingData.State ,'');
        }

        
       
        if (yearBuiltInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.BuiltYear) {
            yearBuiltInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.BuiltYear;
        }
        if (sqrftInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.SquareFeet) {
            sqrftInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.SquareFeet;
        }
        if (foundationTypeInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.TypeOfFoundation) {

            let foundation = hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.TypeOfFoundation;
            foundationTypeInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.TypeOfFoundation;

            let selectProvince = document.querySelector("#select-foundation") as HTMLElement | any;
            let stateButton = selectProvince?.parentElement.querySelector(".select-selected") as HTMLButtonElement;
            const stateSelect = document.querySelector("#select-foundation") as HTMLButtonElement;
       
      
            if(stateButton  && stateSelect){
      
              stateSelect.value = foundation;
              stateButton.innerText = foundation;
              stateButton.style.backgroundColor = "var(--primary-500)";
              stateButton.style.color = "var(--white)";
              stateButton.style.whiteSpace = "nowrap";
              stateButton.style.overflow = "hidden";
              stateButton.style.borderRight = "40px solid var(--primary-500)";
            }
        }
        if (bedRoomInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.NumberOfBedrooms) {
            bedRoomInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfBedrooms;
        }
        if (bathRoomInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.NumberOfBathrooms) {
            bathRoomInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfBathrooms;
        }
        if (kitchenInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.NumberOfKitchens) {
            kitchenInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfKitchens;
        }
        if (poolInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.IsPoolAvailable) {
            poolInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.IsPoolAvailable ? 'Yes' : 'No';
        }
        if (onUtitlityInput && typeof hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.UtilitiesOn === 'boolean') {
            onUtitlityInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.UtilitiesOn ? 'Yes' : 'No';
        }
        if (occupyInput && hmsBookingData.HmsRoleInfo?.HmsPropertyDetail?.isPropertyOccupied) {
            occupyInput.value = hmsBookingData.HmsRoleInfo.HmsPropertyDetail.isPropertyOccupied ? 'Yes' : 'No';
        }
        if (howAboutInput && hmsBookingData?.HmsRoleInfo?.HmsPropertyDetail?.HearAboutHouseMaster) {
            let selectProvince = document.querySelector("#how-about-hms") as HTMLElement | any;
            let stateButton = selectProvince?.parentElement.querySelector(".select-selected") as HTMLButtonElement;
            const stateSelect = document.querySelector("#how-about-hms") as HTMLButtonElement;
            if(stateButton  && stateSelect){
              stateSelect.value = hmsBookingData?.ReferralType;
              stateButton.innerText = hmsBookingData?.ReferralType;
              stateButton.style.backgroundColor = "var(--primary-500)";
              stateButton.style.color = "var(--white)";
              stateButton.style.whiteSpace = "nowrap";
              stateButton.style.overflow = "hidden";
              stateButton.style.borderRight = "40px solid var(--primary-500)";
            }
        }
        
    }

}