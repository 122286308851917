import { stopLoader } from "../util/loader";
import { reformatDate, setTimeAndDate } from "../util/setDateAndTime";

export class Confirmation{
    private recurHeading: HTMLElement;
    private oneTimeHeading: HTMLElement;
    private leadHeading: HTMLElement;
    private osHeading: HTMLElement;
    private appointmentDetails: HTMLElement;
    private inputData: any;
    private frequency: string;
    constructor(){
        this.recurHeading = document.getElementById('recurring_heading') as HTMLElement;
        this.oneTimeHeading = document.getElementById('onetime_heading') as HTMLElement;
        this.osHeading = document.getElementById('appointment_title') as HTMLElement;
        this.leadHeading = document.getElementById('leadflow_title') as HTMLElement;
        this.appointmentDetails = document.querySelector('ul.appointment-list') as HTMLElement;
        this.inputData = JSON.parse(sessionStorage.getItem('rl-post-data') as string);
        this.frequency = this.inputData.JobFrequency;
    }
    showHeading(){
        // Check whether one time or recur
        const flag = this.frequency.toLowerCase();
        if(flag.includes('recurring')){
            this.recurHeading.classList.remove('hidden');
            this.oneTimeHeading.classList.add('hidden');
        }
        else{
            this.recurHeading.classList.add('hidden');
            this.oneTimeHeading.classList.remove('hidden');
        }
    }
    showAppointmentHeading(){
        const flag = this.inputData.IsLeadOnly;
        if(flag){
            this.leadHeading.classList.remove('hidden');
            this.osHeading.classList.add('hidden');
        }
        else{
            this.leadHeading.classList.add('hidden');
            this.osHeading.classList.remove('hidden');
        }
    }
    updateAppointmentDetails(){
        let flag = this.frequency.toLowerCase();
        const arrs:any = {
            "Date and Time": '/brand/_assets/images/brand-img/lead-flow/calendar.svg',
            "Estimate": '/brand/_assets/images/brand-img/lead-flow/tag.svg',
            "Service Address": '/brand/_assets/images/brand-img/lead-flow/location-marker.svg',
            "Contact Info": '/brand/_assets/images/brand-img/lead-flow/mail.svg',
        };
        Object.keys(arrs).forEach((a:string)=>{
            const html:string = this.generateLi(a, arrs[a] ,flag);
            this.appointmentDetails.innerHTML = this.appointmentDetails.innerHTML.concat(html);
        });
    }
    generateLi(category:string, img:string, flag:string) : string{
        let text1, text2, span, mobile = '';
        let html = ``;
        if(category.includes('Estimate') && !flag.includes('recurring')){
            const estimate = sessionStorage.getItem('mollyEstimate');
            const estimateData = JSON.parse(estimate as string);
            const oneTimeClean = this.inputData.JobFrequencyDetail.includes('one time');
            if (estimateData && oneTimeClean) {
                text1 = `$${estimateData?.estimateOccasionalRangeLow} - $${estimateData?.estimateOccasionalRangeHigh}`;
            } else if (estimateData && !oneTimeClean) {
                text1 = `$${estimateData?.estimateMoveRangeLow} - $${estimateData?.estimateMoveRangeHigh}`;
            }else {
                text1 = `$${estimateData?.estimateMoveRangeLowSaturday} - $${estimateData?.estimateMoveRangeHighSaturday}`;
            }


            if(text1 == null || text1 == undefined){
                return '';
            }
            span = 'yes';
        }
        else if(category.includes('Date and Time') && !this.inputData.IsLeadOnly){
            // text1 = sessionStorage.getItem('BookingDate');
            // text2 = sessionStorage.getItem('BookingTime');
            text1 = this.processString(sessionStorage.getItem('userSelectDatae')as any);
        }
        else if(category.includes('Service Address')){
            let addr = sessionStorage.getItem('sAddressParam') as any;
            // addr = addr.split(",");
            // text1 = addr[0] + ", " + addr[1] + ", ";
            // text2 = addr[2];

            const stateCode = sessionStorage.getItem("stateCode");
            const countryName = sessionStorage.getItem("countryName");
            let finalAddress:any = ''
            let getsuggestionFlag = sessionStorage.getItem("suggestion");
            if(getsuggestionFlag === "true") {
                finalAddress = `${this.inputData.Address}, ${this.inputData.City}, ${stateCode}, ${this.inputData.PostalCode}, ${countryName}`;
                sessionStorage.setItem("suggestion","false");
            }else {
                finalAddress = `${this.inputData.Address} ${this.inputData.PostalCode}`;
                sessionStorage.setItem("suggestion","false");
            }

            text1 = finalAddress;
        }
        else if(category.includes('Contact Info')){
            text1 = this.inputData.FirstName + " " + this.inputData.LastName;
            text2 = this.inputData.Email;
            mobile = this.inputData.Phone;
        }

        if(text1 && img){
            html = html.concat(`<li class="appointment-list-item"> <div class="icon-block">`);
            html = html.concat(`<img src="${img}" alt="calendar" width="24" height="24">`);
            html = html.concat(`<div class="appointment-detail-wrap"><div class="icon-block-wrap"><p class="icon-block-heading">${category}</p><p class="icon-block-text">${text1}</p>`);
        }
        if(text2){
            html = html.concat(`<p class="icon-block-text">${text2}</p>`);
        }
        if(span){
            html = html.concat(`<span class="icon-block-sub-text">plus any applicable sales taxes</span>`);
        }
        if(mobile){
            html = html.concat(`<p class="icon-block-text">${mobile}<span>(mobile)</span></p>`);
        }
        html = html.concat(`</div>`);
        if(category.includes('Contact Info')){
            html = html.concat(`<div class="icon-block-wrap"><p class="icon-block-heading">Preferred Method of contact:</p><p class="icon-block-text">${sessionStorage.getItem('PreferredCommunicationText')}</p></div>`);
        }
        html = html.concat(`</div> </div> </li>`);
        return html;
    }
     processString(str: string): string {

        //this is to check if there are 2 or 3 commas, if 2 then we add one more after the first string
        if(str.split(",").length === 3){
            const newValues: string[] = str.split(" ");
            const updatedArray: string = newValues.slice(1, str.length).join(" ").replace(/  /g, " ");
            str = `${newValues[0]}, ${updatedArray}`;
        }
    
        // Check if the string is already in the desired format
        let regex: RegExp = /^[A-Za-z]+, [A-Za-z]+ \d+, \d{4}, [A-Za-z]+$/;
        if (regex.test(str)) {
            return str;
        }
        else{
            if (!isNaN(Date.parse(str.split(",")[0]))) {
              return `${setTimeAndDate(str.split(",")[0])}${(str.split(",")[1] === ' undefined') ? "" : "," + str.split(',')[1]}`;
            } else {
                return reformatDate(str);
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    stopLoader();
    const conceptCode: any = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const divTag: any = document.getElementsByClassName('mly-confirmation-section');
    if (conceptCode === "MLY" && divTag?.length>0) {
        const c = new Confirmation();
        c.showHeading();
        c.showAppointmentHeading();
        c.updateAppointmentDetails();
    }

});