import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";

import { getWeblocationPathGroupWithSiteConfig } from "./share";

export async function getMessagesData() {
    const urlSiteConfig = getWeblocationPathGroupWithSiteConfig();
    try{
        if(urlSiteConfig){
            const cachedResponse = await getCachedAPIResponse(urlSiteConfig, apiCaching.SiteConfig, false);
            if (cachedResponse) {
                return cachedResponse
            }
        }
        
    }
    catch{
        console.error("Error in fetching site config");
        return {}
    }
}

