import axios from "axios";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { setTimeAndDate, reformatDate } from "../util/setDateAndTime";

let Images: any = []
export class Step4 {
    constructor() {
        if (sessionStorage.getItem('booking')) {
            const data: any = sessionStorage.getItem('booking')
            const getData: any = JSON.parse(data)

            const images: any = getData?.FileUrls
            const imagesFlag = images?.some((x: any) => x.FileUrl != '')
            if (imagesFlag) {
                this.getImagesFromApi()
            }
            else {
                this.setData()
                
            }
            this.authorableConfirmationTitle(getData.onlineScheduling);
        }

        const container = document.querySelector('.confirmation-section .primary-outline-btn')

        if (container) {
            container.addEventListener('click', () => {
                window.location.href = '/'
            })
        }

    }


    public authorableConfirmationTitle(appoinmentFlag: boolean){
        const appointmentTitle = document.getElementById("appointment_title") as HTMLElement;
        const leadTitle = document.getElementById("leadflow_title") as HTMLElement;
        if(appoinmentFlag && appointmentTitle){
            // console.info("Lead flow title hidden as confirmation is detected as an Appointment")
            appointmentTitle.classList.remove("hidden");
            if(leadTitle && !leadTitle.classList.contains('hidden'))
                leadTitle.classList.add("hidden");
        }
        else if(!appoinmentFlag && leadTitle){
            // console.info("Appointment title hidden as confirmation is detected as a Lead Flow")
            leadTitle.classList.remove("hidden");
            if(appointmentTitle && !appointmentTitle.classList.contains("hidden"))
                appointmentTitle.classList.add("hidden");
        }
    }
    getImagesFromApi() {
        var myHeaders = new Headers();
        myHeaders.append("apikey", "dneiweb-oZlRRNnlSLCWP37");
        myHeaders.append("Content-Type", "application/json");

        const data: any = sessionStorage.getItem('booking')
        const getData: any = JSON.parse(data)

        const images: any = getData?.FileUrls

        const imagesNames = images.filter((x: any) => x.FileUrl != '').map((x: any) => x.FileUrl)

        // var raw = [
        //     "431d22ea-679d-90bc-b730-5d276357cb67.jpg",
        //     "81d8eac0-bad9-9f7b-71b1-6895208e6dd7.jpg",
        //     "e54694e4-a4fa-1699-5c7f-283b408c6b70.jpg"
        // ]


        const request = {
            method: 'POST',
            url: `${process.env.JS_API_URL}/${process.env.JS_DigitalOrchestrations_URL}/v1/public/onlineschedulerfiledownload/getpresignedurl?apikey=${process.env.JS_API_KEY}`,
            data: imagesNames
        };

        apiRequest(request)
            .then((response: any) => {

                for (let i in response?.presignedURLToDownload) {
                    Images.push(response?.presignedURLToDownload[i])
                }

                this.setData()


            })
            .catch((err) => {

            });

    }

    setData() {
        const data: any = sessionStorage.getItem('booking')
        const getData: any = JSON.parse(data)
        const infoContainer: any = document.querySelector('.appointment-details')
        const servicePayload = getData?.ServiceCat ? " ServiceCat:" + getData?.ServiceCat: '' 
        const serviceServiceType = getData?.ServiceType ? " ServiceType:" + getData?.ServiceType: '' 
        const stateCode = sessionStorage.getItem("stateCode");
        const countryName = sessionStorage.getItem("countryName");
        let finalAddress:any = ''
        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        if(getsuggestionFlag === "true") {
            finalAddress = `${getData.Address}, ${getData.City}, ${stateCode}, ${getData.PostalCode}, ${countryName}`;
            sessionStorage.setItem("suggestion","false");
        }else {
            finalAddress = sessionStorage.getItem("manualEnteredAddress");
            sessionStorage.setItem("suggestion","false");
        }
        const selServiceType = sessionStorage.getItem("serviceType");
        
        const lastName = getData?.LastName ?? '';
        const firstName = getData?.FirstName ?? '';

        const personalinfo = `<div class="appointment-wrap">
                <p class="appointment-heading">Contact Information</p>
                <span class="appointment-text">${firstName} ${lastName}</span>
                <span class="appointment-text">${getData.Phone}</span>
                <span class="appointment-text">${getData.Email}</span>
            </div>`
        infoContainer?.insertAdjacentHTML('beforeend', personalinfo);

        const address = `<div class="appointment-wrap">
        <p class="appointment-heading">Service Address</p>
        <span class="appointment-text">${finalAddress} ${getData?.Address2 ?? ''}</span>
       </div>`

        if(!getData?.isContact){
            infoContainer?.insertAdjacentHTML('beforeend', address);
        }

        function processString(str: string): string {

            //this is to check if there are 2 or 3 commas, if 2 then we add one more after the first string
            if(str.split(",").length === 3){
                const newValues: string[] = str.split(" ");
                const updatedArray: string = newValues.slice(1, str.length).join(" ").replace(/  /g, " ");
                str = `${newValues[0]}, ${updatedArray}`;
            }
        
            // Check if the string is already in the desired format
            let regex: RegExp = /^[A-Za-z]+, [A-Za-z]+ \d+, \d{4}, [A-Za-z]+$/;
            if (regex.test(str)) {
                return str;
            }
            else{
                if (!isNaN(Date.parse(str.split(",")[0]))) {
                  return `${setTimeAndDate(str.split(",")[0])}${(str.split(",")[1] === ' undefined') ? "" : "," + str.split(',')[1]}`;
                } else {
                    return reformatDate(str);
                }
            }
        }
        

        if (getData.ScheduleID && getData.userSelectDatae.split(',')[0] && !getData.IsLeadOnly){
            const dateTime = `<div class="appointment-wrap">
                <p class="appointment-heading">Date and Time of Service </p>
                <span class="appointment-text">${processString(getData.userSelectDatae)} </span>
            </div>`
            infoContainer?.insertAdjacentHTML('beforeend', dateTime)
        }

        else {
            const c1 = document.getElementById('request-appointment')
            const c2 = document.getElementById('request-call')
            if (c1)
                c1.style.display = 'none'
            c2?.removeAttribute('style')


        }

        if(selServiceType){
            const service = `<div class="appointment-wrap">
            <p class="appointment-heading">Service Request</p>
            <span class="appointment-text">${selServiceType ? selServiceType : 'N/A'}</span>
        </div>`;
            
            if(selServiceType !== "undefined" && !getData.isContact){
                infoContainer?.insertAdjacentHTML('beforeend', service);
            }
        }
        const osNote = sessionStorage.getItem("os_note");
        const values = [];

        if (osNote) values.push(osNote);
        if (servicePayload) values.push(servicePayload);
        if (serviceServiceType) values.push(serviceServiceType);

        const result = values.join(', ');


        const note = `<div class="appointment-wrap">
        <p class="appointment-heading">Service Description</p>
        <span class="appointment-text">${result}</span>
    </div>`
        if (result)
            infoContainer?.insertAdjacentHTML('beforeend', note);


        const getFloor = sessionStorage.getItem("floorService");
        const getFloorLabel = sessionStorage.getItem("floorLabel");
        if (getFloor && getFloorLabel) {
            const floor = `<div class="appointment-wrap">
                    <p class="appointment-heading">${getFloorLabel}</p>
                    <span class="appointment-text"> ${getFloor}</span>
                </div>`;
            infoContainer?.insertAdjacentHTML('beforeend', floor);
        }
       

        const container = document.querySelector('.confirmation-section .inspection-fee')
        if (container && getData?.fee)
            container.innerHTML = `<strong>$${getData?.fee}</strong>`


        const images: any = getData?.FileUrls

        const imagesFlag = images?.some((x: any) => x.FileUrl != '')

        const imagesCont = ` <div class="appointment-wrap">
                         <p class="appointment-heading">Uploaded Images</p>
                         <div class="uploaded-images">
                         ${Images.map((x: any) => {
            return (
                `<img src="${x}" alt="map" width="88" height="88">`
            )
        }).join('')
            }                            
                         </div>
                     </div>`



        if (imagesFlag)
            infoContainer?.insertAdjacentHTML('beforeend', imagesCont);
    }


}


    new Step4();
