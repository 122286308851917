// import { MegaMenuHandler } from "../../global";
import { StickyHeader } from "../../global";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { getFooterLink, getWeblocationPathGroupWithFooterConfig } from "../util/share";
import { SetLocalHeader } from "./SetLocalHeader";
import {setServices} from "./serviceLinks";

export default class MenuGeneratorDynamic {
  constructor() {
    // Retrieve the necessary data once in the constructor
    const dbaName: string | null = localStorage.getItem('dbaName');
    const webId: string | null = localStorage.getItem('weblocationId');
    this.getFooterMenu(dbaName);
    if (webId && dbaName && !window.location.href.includes(dbaName)) {
      this.getMenu();
    }
    const localWeblocId = (document.getElementById('local_weblocationId') as HTMLInputElement)?.value;
    const franchiseWebLocationId = sessionStorage.getItem("franchiseWebLocationId");
    if (franchiseWebLocationId || localWeblocId) {
      this.hideOurTeamsMenu();
      this.fetchPhotoGalleryData();
    }
  }

   async hideOurTeamsMenu() {
    try {
      sessionStorage.removeItem("ourTeamObject");
      const localWebID = sessionStorage.getItem("franchiseWebLocationId");
      const webID = document.getElementById('weblocationId') as HTMLInputElement;
      const webLocationId = webID?.value ? webID?.value : localWebID;
      const apiUrl = apiConfig.ourTeam + webLocationId + `?apikey=${process.env.JS_API_KEY}`;

      const request = {
        method: 'GET',
        url: apiUrl
      };
     
      const result:any = await apiRequest(request);
      if(result){
      sessionStorage.setItem('ourTeamObject', JSON.stringify(result));
      if (result?.teamInfo == null && result.teamSize == 0) {
        this.hideTeamAndPhotoMenu("team");
      }
     }
    } catch (err) {
      console.log('Error in rendering our teams section.', err);
      this.hideTeamAndPhotoMenu("team");
    }
  }

  private hideTeamAndPhotoMenu(menuToBeHidden:string):void{

    let targetItem;
    //We can avoid this entire switch case, but it is used so that we can change the ID authored from CP in one place.
    switch(menuToBeHidden){
      case 'photo':
        targetItem = document.getElementById("photogallery_menu");
        if(targetItem)
          findParent(targetItem);
        break;
      case 'team':
        targetItem = document.getElementById("meettheteam_menu");
        if(targetItem)
          findParent(targetItem);
        break;
      default:
        break;
    }

    function findParent(target:any){
      //This is the root menu class defined.
      const menuParentClass = document.querySelector(".main-nav-warpper");
      if(menuParentClass){
        //if any new menu levels come in, add here to this array
        let targetClass = ["menu-block", "subMenu-li", "nav-link-item"];
        try{
           //Using event bubbling to keep checking till the root menu class which is held in menuParentClass
            while(target!== menuParentClass){
              //checking for all items in the array defined above to find our target class
              const containsClass = targetClass.some(cls => target.classList.contains(cls));
              if(containsClass){
                target.classList.add("hidden");
                break;
              }
              else if(target.parentNode){
                //traversing one level up in the DOM tree.                 
                target = target.parentNode;
              }
              else{
                break;
              }
            }
        }
        catch(err){
          console.log(`Error -${err}- in handling menu hide function for ${menuToBeHidden} menu`);
        }
      }
      else{
        console.log("Error parent menu class name has been modified from the original 'main-nav-warpper'. Exiting hiding")
      }
      
    }
  }

  async fetchPhotoGalleryData() {
    let photos : any = localStorage.getItem('galleryPhotos');    
    photos = JSON.parse(photos);
    if(photos && photos.length < 6) {
      this.hideTeamAndPhotoMenu("photo");
    }
  }

  getFooterMenu(dbaName: any) {
    if(dbaName) {
      let urlName = getWeblocationPathGroupWithFooterConfig()  //`${window.location.origin}/${dbaName}/config-files/_footer-config` // `https://dig-www-nei-rbw2-test.nblydev.com/${dbaName}/config-files/_footer-config`;
      const request = {
        method: 'GET',
        url : urlName
      }
      apiRequest(request)
        .then((response: any) => {
          this.insertDataIntoFooterMenu(response);
        })
        .catch((err) => {
          console.log(err);
        })
      
    }
  
  }
      

  insertDataIntoFooterMenu(footerHTML? : any) {
    const parser = new DOMParser();
    const newFooter = parser.parseFromString(footerHTML, 'text/html');
    const footerConfigHTML = document.querySelector('.fb-wrapper-link') as HTMLElement;
    const newFooterLinks = newFooter.querySelector('.fb-wrapper-link');
    if(newFooterLinks && footerConfigHTML) {
      footerConfigHTML.innerHTML = newFooterLinks.innerHTML
    }
    this.getLocalHeaderData();
  }

  getLocalHeaderData() {
    // service links 
    const headerLinks: any = document.querySelectorAll('.dynamic-service-menu')
    let data: any = []
    if (headerLinks.length > 0) {
    headerLinks.forEach((item: any) => {
        data.push({
            name: item?.querySelector('a span').textContent.trim(),
            link: item?.querySelector('a').href

        })
    })
    setServices(data);
   } else {
      let serviceData:any = getFooterLink();
      setServices(serviceData);
      }
  }

  async getMenu() {
    const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');
    const webId = (document.getElementById('weblocationId') as HTMLInputElement)?.value ?? localStorage.getItem('weblocationId');
    const locationDoingBusinessAs = sessionStorage.getItem('doingBusinessAs');
    const url = window.location.origin // === "localhost" ? `https://dig-www-nei-mre2-test.nblydev.com` : window.location.origin
    const dynamicUrl = `${url}/localmenu/${webId}/${dbaName}`;
    const request = {
      method: 'GET',
      url: dynamicUrl,
    };
    try {
    const response:any = await apiRequest(request);
    this.insertMenuIntoDOM(response);
    const stickyHeader = new StickyHeader();
    const setLocalHeader = new SetLocalHeader();
    stickyHeader.dynamicMenuV2();
    if(dbaName && locationDoingBusinessAs){
      const newHTML = `
          <svg class="icon"> <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use></svg>
              <div>
                  <span class="local-address">${locationDoingBusinessAs}</span> Change location
              </div>
          `;
          setLocalHeader.setHamburgerSubMenuInnerDba(newHTML)
  }
    
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  }


  insertMenuIntoDOM(menuHTML?: string) {
    if (menuHTML) {
      const mainNav = document.querySelector('.main-nav');
      const topNav = document.querySelector('.header-wrapper .brand-cta-list');
      const parser = new DOMParser();
      const newNav = parser.parseFromString(menuHTML, 'text/html');
      const newMainNav = newNav.getElementById("bottom-menu") as HTMLElement;
      const newTopNav = newNav.getElementById("top-menu") as HTMLElement;
      if (mainNav && newMainNav) {
        mainNav.innerHTML = newMainNav.innerHTML; // Set innerHTML to an empty string if menuHTML is undefined
        // new MegaMenuHandler()
      }
      if (topNav && newTopNav) {
        topNav.innerHTML = newTopNav.innerHTML;
      }
    }
  }

  
}

const menuGenerator = new MenuGeneratorDynamic();
