export function extractServices(data: any) {
    const services: any = [];
    const level2 = data?.menu ? data?.menu : []
    let serviceData: any = []
    level2?.forEach((item2: any) => {
        if(!item2.service)
        serviceData.push(item2)
    else
        item2?.service?.forEach((item3: any) => {
            serviceData.push(item3)
        })
    })

    return serviceData;
}

