import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";

export function calcEstimate(bookingPayload:any){
    startLoader();
    let payload ={
        "franchiseWebLocationId": bookingPayload.WebLocationId,
        "LeadSourceId": bookingPayload.LeadSourceId,
        "SecondaryLeadSourceId": 0,
        "Note": bookingPayload.Note,
        "EstimateTitle": "null",
        "FirstName": bookingPayload.FirstName,
        "LastName": bookingPayload.LastName,
        "Address": bookingPayload.Address + bookingPayload.Address2,
        "City": bookingPayload.City,
        "State": bookingPayload.State,
        "PostalCode": bookingPayload.PostalCode.trim(),
        "Email": bookingPayload.Email,
        "Phone": bookingPayload.Phone,
        "PreferredCommunicationType": "null",
        "EstimateSquareFeet": Number(bookingPayload.EstimateSquareFeet),
        "EstimateBedrooms": Number(bookingPayload.EstimateBedrooms),
        "EstimateBathrooms": Number(bookingPayload.EstimateBathrooms),
        "ReceiveEmailUpdates": true
    };
    const request = {
        method: 'POST',
        url: apiConfig.calculateEstimate,
        data: payload
    };

   return apiRequest(request)
        .then((response: any) => {
            console.log('API success');
            sessionStorage.setItem("mollyEstimate",JSON.stringify(response));
            stopLoader();
        })
        .catch((error:any) => {
            stopLoader();
            console.log('API fail');
        })
    
}