import axios from "axios";
import { brandLocalHeader } from "../common";
import { apiConfig } from "../config/apiConfig";
import { StateHandler } from "../util/getStateFun";
import {startLoader, stopLoader} from "../util/loader"
import { getCountryCode } from "../location-search-map/getCountryCode";

export class GetLocateLocation {
  public locateLocation(sDeviceType: string): void {

    let sAddressParam = '';

    if (sDeviceType === 'desk') {
      const element = document.getElementById('local-search-modal') as HTMLInputElement;
      const zipcode = element?.value.trim();
      const domainName = location.hostname.toLowerCase();
      const isWebSite =
        domainName.indexOf('-ca-') === -1 && domainName.indexOf('.ca') === -1 && domainName.indexOf('-ca.') == -1
          ? 'us'
          : 'ca';

      if (isWebSite === 'ca') {
        const formattedZipcode = zipcode.indexOf(' ') === -1 ? zipcode.replace(/^(.{3})(.*)$/, '$1 $2') : zipcode;
        sAddressParam = formattedZipcode;
      } else if (zipcode && zipcode.length > 0) {
        sAddressParam = zipcode;
      }
    } else if (sDeviceType === 'mob') {
      const inputLocalZip = this.getValue('inputLocal-zip');
      if (inputLocalZip.length > 0) {
        sAddressParam = inputLocalZip;
      }
    }

    startLoader();

    const apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(sAddressParam));

    axios.get(apiUrl, {
      headers: {
        'Cache-Control': 'max-age=0',
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.length > 0) {
          stopLoader();
          const sRedirectURL = result[0].locationWebsiteUrl ?? '';

          sessionStorage.setItem('alternateConcept', result[0].alternateConcept);
          sessionStorage.setItem('franchiseWebLocationId', result[0].franchiseWebLocationId);
          let countryCode = getCountryCode();
          sessionStorage.setItem('countryCode', countryCode);
          sessionStorage.setItem('doingBusinessAs', result[0].doingBusinessAs);
          sessionStorage.setItem('franchiseeName', result[0].franchiseeName);
          sessionStorage.setItem('sAddressParam', sAddressParam) 
          const hostURL = new URL(sRedirectURL);
          const pathname = hostURL.pathname;
          const redirectURL = new URL(window.location.href)
          const redirectHostName = redirectURL.host
          const conceptId:any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
          if(conceptId==25){
            localStorage.setItem('hms_redirect_local', 'true');
          }

          const domainName = location.hostname.toLowerCase();
          const isWebSite =
            domainName.indexOf('-ca-') === -1 && domainName.indexOf('.ca') === -1 && domainName.indexOf('-ca.') == -1
              ? 'us'
              : 'ca';
          if(isWebSite=='ca' && sRedirectURL){
            window.location.href = sRedirectURL;
          }else{
            window.location.replace(`https://${redirectHostName}${pathname}`);
          }
          localStorage.setItem('alternateConcept', result[0].alternateConcept);
          localStorage.setItem('franchiseWebLocationId', result[0].franchiseWebLocationId);
          // brandLocalHeader();
        } else {
          const modalElement = document.querySelector('#glb-find-local') as HTMLElement;
          modalElement.classList.add('modal');
          const text1 = document.getElementById("step1-text1") as HTMLElement;
          const text2 = document.getElementById("step1-text2") as HTMLElement;      
           text1.style.display = "none";
            text2.style.display = "block";

            stopLoader();
        }
      })
      .catch((error) => {
        stopLoader();
        const duplicate = error.response.data;

        if (duplicate.Message === 'Full address is needed to narrow down results') {
          const stateHandler = new StateHandler();
          stateHandler.getStateFun(); 

          const step1 = document.querySelector('.step-1') as HTMLElement;
          const step2 = document.querySelector('.step-2') as HTMLElement;

          if (step1) {
            step1.classList.add('hidden');
          }

          if (step2) {
            step2.classList.remove('hidden');
            step2.classList.add('block');

            const closeLocalModal = document.querySelector('.close-modal-btn');
            closeLocalModal?.addEventListener('click', function handleClick(event) {

            step2.classList.add("hidden");
            step1.classList.remove("hidden");
            })
          }
          const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
          modalZipCode.value = sAddressParam;
        }
      });
  }

  private getValue(id: string): string {
    const element = document.getElementById(id) as HTMLInputElement;
    return element ? element.value : '';
  }
}








