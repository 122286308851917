import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";
import { storeBookingApiPayload } from "../util/share";
let moment = require('moment');

export function callBookingApi(bookingPayload: any){
    startLoader();
    const request = {
        method: 'POST',
        url: apiConfig.BOOKING_API_URL,
        data: bookingPayload
    };
    apiRequest(request)
        .then((response: any) => {
            if(response){
                storeBookingApiPayload(bookingPayload, response);
                console.log('API success');
                window.location.pathname = '/confirmation';
            }
            else{
                console.log('Booking API returns no response');
            }
            stopLoader();
        })
        .catch((error:any) => {
            stopLoader();
            console.log('API fail');
        })
}

export async function getFranchiseDetails(addressParam:string) : Promise<any> {
    const request = {
        url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(addressParam))
    };
    try{
        const result:any = apiRequest(request);
        return result;
    }
    catch(error){
        return Promise.reject('Error');
    }    
}

export function determineFlowType(WebLocationId:number) : any {
    // Function to determine whether OS flow or Lead flow
    const request = {
        method: 'GET',
        url: `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${WebLocationId}`,
    };
    try{
        const result = apiRequest(request);
        return result;
    }
    catch(error){
        throw error;
    } 

}

export function checkTechAvailability() : Promise<any> {
    const weblocationId = sessionStorage.getItem('franchiseWebLocationId');
    const zipValue = sessionStorage.getItem('zipcode');
    const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=56&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=true&apikey=${process.env.JS_API_KEY}`
    const request = {
        method: 'GET',
        url: dynamic_url,
    };
    try{
        return apiRequest(request);
    }
    catch(error){
        throw error;
    }
    
}

// export function openPropertyValidationModal(addressParam : string, suggestedParam: string){
//     stopLoader();
//     const callPopupModal = document.getElementById('address-modal')
//     callPopupModal?.click()
//     const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
//     const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
//     if(enteredAddr && suggestAddr){
//         enteredAddr.textContent = addressParam;
//         suggestAddr.textContent = suggestedParam;
//     }
//     // const modalDiv = document.getElementById('property-address-validation-modal') as HTMLElement;
//     // modalDiv.classList.remove("hidden");
//     document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", ()=>{
//         flagShowPopup = false
//         startLoader();
//         let output:any;
//         if((document.getElementById('suggested-address-radio') as HTMLInputElement)?.checked){
//             output = suggestAddr;
//         }
//         else{
//             output = enteredAddr;
//         }
//         sessionStorage.setItem('sAddressParam', output);
//     });
    
// }