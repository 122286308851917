import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { ResidentialForm } from "../hero_component";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { startLoader, stopLoader } from "../util/loader"
export class LocateAddressBumper {
  private address1: HTMLElement
  private sendButton: HTMLButtonElement | null
  constructor() {
    const modalContent = document.querySelector('.find-local-modal .modal-loc-div');
    if (modalContent) {
      modalContent.classList.add('banner-wizard');
    }
    this.sendButton = document.querySelector('.step-2 .form-cta button');
    if (this.sendButton) {
      this.sendButton.addEventListener("click", this.handleNextBtnClick.bind(this));
    }
    this.address1 = document.getElementById('modal-address-1') as HTMLInputElement
    document.addEventListener('input', () => {
      if ((document.getElementById('modal-address-1') as HTMLInputElement).value) {
        this.sendButton?.classList.remove('disable-btn')
        this.sendButton?.classList.add('primary-btn')
      }
      else {
        this.sendButton?.classList.remove('primary-btn')
        this.sendButton?.classList.add('disable-btn')

      }
    })

  }

  private enableButtonFindLocal(): void {
    const nextBtn = document.querySelector('.step-2 .disable-btn');
    if (nextBtn) {
      nextBtn.addEventListener("click", this.handleNextBtnClick.bind(this));
    }

  }

  private getFormFields(): any {
    const fields = {

      zipCode: (document.getElementById('modal-zipcode') as HTMLInputElement).value,
      address1: (document.getElementById('modal-address-1') as HTMLInputElement).value,
      address2: (document.getElementById('modal-address-2') as HTMLInputElement).value,
      city: (document.getElementById('modal-city') as HTMLInputElement).value,
      state: (document.getElementById('modal-states') as HTMLSelectElement).value,
    };
    return fields;
  }


  public validateForm(): boolean {
    const formFields = this.getFormFields();
    document.querySelectorAll('.step-2 .error-msg').forEach(e => e.remove());
    // document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
    document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
    const errors: any = {};
    if (formFields.address1 === '') {
      errors['modal-address-1'] = 'Address Line 1 is required.'

    }

    if (formFields.zipCode === '') {

      errors['modal-zipcode'] = 'ZIP code is required.';

    } else
      if (formFields.zipCode && formFields.zipCode != '' && !REGEX.sendZip.test(formFields.zipCode.trim())) {

        errors['modal-zipcode'] = 'Invalid zip code format.';
      }

    Object.keys(errors).forEach((fieldId) => {

      const field: any = document.getElementById(fieldId);

      if (field && field.parentNode) {

        const errorMessage = errors[fieldId];

        const errorElement = document.createElement('span');

        errorElement.className = 'error-msg';

        errorElement.textContent = errorMessage;

        field.classList.add("invalid-field");

        field.parentNode.appendChild(errorElement);

      }

    });

    return Object.keys(errors).length === 0;

  }



  public handleNextBtnClick(event:Event): void {
    if (this.validateForm()) {
      startLoader()
      this.handleNextBtnSubmit(event)
    }
  }
  public handleNextBtnSubmit(event:Event): void {
    const inputAddressBumper = document.querySelector('.step-2 #modal-address-1') as HTMLInputElement;
    const inputAddressBumper2 = document.querySelector('.step-2 #modal-address-2') as HTMLInputElement;
    const inputCity = document.querySelector('.step-2 #modal-city') as HTMLInputElement;
    const inputState1 = document.querySelector('.step-2 #modal-states') as HTMLInputElement;
    const inputZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
    const sAddressBumper = inputAddressBumper?.value.trim() || '';
    const sAddressBumper1 = inputAddressBumper2?.value.trim() || '';
    const sCityBumper = inputCity?.value.trim() || '';
    const sStateBumper = inputState1?.value.trim() || '';
    const sZipCodeBumper = inputZipCode?.value.trim() || '';
    const sAddressParam = `${sAddressBumper} ${sAddressBumper1} ${sCityBumper} ${sStateBumper} ${sZipCodeBumper}`;
    const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(sAddressParam));
    fetch(apiUrl)
    .then((response) => response.json())
    .then((result) => {
    
      if (result.length > 0) {
        const jsonItems = result.filter((e:any) => e.franchiseId === e.franchiseId);
        if (jsonItems.length === 1) {
          const shortFormAddressBumper = sessionStorage.getItem('shortFormAddressBumper');
          if(shortFormAddressBumper === "true"){
            sessionStorage.removeItem('shortFormAddressBumper');
            const obj = {
              'franchiseId': result[0].franchiseId,
              'franchiseWebLocationId': result[0].franchiseWebLocationId

            }
            const addressParam = {
              "address": {
                "state": sStateBumper,
                "city": sCityBumper,
                "postalCode": sZipCodeBumper
              }, "title": sAddressBumper
             }
              const shortFormAddressBumper:any = new ResidentialForm();
              shortFormAddressBumper.handleSubmit(event,obj,addressParam)
          }else{
              const item = jsonItems[0];
              const sRedirectURL = item.locationWebsiteUrl;
              sessionStorage.setItem('alternateConcept', item.alternateConcept);
              sessionStorage.setItem('franchiseWebLocationId', item.franchiseWebLocationId);
              sessionStorage.setItem('doingBusinessAs', item.doingBusinessAs);
              sessionStorage.setItem('franchiseeName', item.franchiseeName);
              sessionStorage.setItem('sAddressParam', sZipCodeBumper);
              let countryCode = getCountryCode();
              sessionStorage.setItem('countryCode', countryCode);
        
              if (sRedirectURL) {
                if (sRedirectURL.startsWith('http:')) {
                  window.location.href = sRedirectURL.replace('http:', 'https:');
                } else if (sRedirectURL.startsWith('https:')) {
                  window.location.href = sRedirectURL;
                }
              }
          }
        }
        else{
          // const ct1 = document.getElementById('step-2-modal');
          // const ct2 = document.getElementById('step-3-modal');
          // console.log({ ct1, ct2 });
        
          // ct1?.classList.add('hidden');
          // ct2?.classList.remove('hidden');
       
          // const input = document.getElementById("local-search-modal") as HTMLInputElement;
          // input.value = zipCode;

        

        

          const text1 = document.getElementById("step1-text1") as HTMLElement;
          const text2 = document.getElementById("step1-text2") as HTMLElement;
          text1.style.display = "none";
          text2.style.display = "block";

          const step1 = document.querySelector('.step-1') as HTMLElement;
          const step2 = document.querySelector('.step-2') as HTMLElement;

          step2.classList.add('hidden');
          step1.classList.remove('hidden');

        }
      }
      else{
        // const ct1 = document.getElementById('step-2-modal');
        // const ct2 = document.getElementById('step-3-modal');
        // console.log({ ct1, ct2 });
      
        // ct1?.classList.add('hidden');
        // ct2?.classList.remove('hidden');

        const text1 = document.getElementById("step1-text1") as HTMLElement;
        const text2 = document.getElementById("step1-text2") as HTMLElement;
        text1.style.display = "none";
        text2.style.display = "block";

        const step1 = document.querySelector('.step-1') as HTMLElement;
        const step2 = document.querySelector('.step-2') as HTMLElement;

        step2.classList.add('hidden');
        step1.classList.remove('hidden');
        

      }
      stopLoader();
    });
    
  }
}