import axios from "axios";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { StateHandler } from "../util/getStateFun";
import { startLoader, stopLoader } from "../util/loader";
import { getMessagesData } from "../util/siteConfig"
import { LocateAddressBumper } from "../header/locateAddressBumper";
import { capitalizeString, getFranchiseWebLocationId, insertAfterUrl } from "../util/share";
import { getBrandDetailsFromBrandJson, getCampaignName, storeBookingApiPayload } from "../util/share";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

let messageData: any = {}
interface LeadSourceArray {
    find(arg0: (source: LeadSource) => boolean): LeadSource | undefined;
}
declare global {
    interface Window {
        getFormFields?: Record<string, any>;
        isShortForm?:any
    }
}
interface LeadSource {
    syncGenericLeadSourceId: number;
    leadSourceName: string;
    isActive: boolean;
    referralTypeId: string;
    referralSourceId: any;
}
export class ResidentialForm {
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private phoneInput: HTMLInputElement;
    private addressInput: HTMLInputElement;
    private submitButton: any;
    private privateSuggestedArray = [];
    private form: any;

    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.asideCardToMobile
        });

        window.addEventListener('resize', this.asideCardToMobile);
        const containerData = document.querySelector('.aside-card.tabs-mobile');
        this.asideCardToMobile();
        const nameInputDesk = document.getElementById('req-name') as HTMLInputElement;
        const lastNameInputDesk = document.getElementById('req-lname') as HTMLInputElement;
        const nameInputMob = document.getElementById('req-name-mobile') as HTMLInputElement;
        const emailInputDesk = document.getElementById('req-email') as HTMLInputElement;
        const emailInputMob = document.getElementById('req-email-mobile') as HTMLInputElement;
        const phoneInputDesk = document.getElementById('req-phone') as HTMLInputElement;
        const phoneInputMob = document.getElementById('req-phone-mobile') as HTMLInputElement;
        const addressInputDesk = document.getElementById('req-address') as HTMLInputElement;
        const addressInputMob = document.getElementById('req-address-mobile') as HTMLInputElement;
        this.nameInput = nameInputDesk ? nameInputDesk : nameInputMob;
        this.lastNameInput = lastNameInputDesk;
        this.emailInput = emailInputDesk ? emailInputDesk : emailInputMob;
        this.phoneInput = phoneInputDesk ? phoneInputDesk : phoneInputMob;
        this.addressInput = addressInputDesk ? addressInputDesk : addressInputMob;
        this.submitButton = document.querySelectorAll('.residential-form .primary-btn');
        this.submitButton?.forEach((btn: any) => {
            btn.addEventListener('click', this.setAutoSuggested.bind(this))
        });

        const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;
        if (pageType && pageType.toLocaleLowerCase() !== "opus 2 location map") {
            getMessagesData()?.then(resp => {
                messageData = resp
            });
        }

        this.initializeInputNameRestriction();
        this.initializeInputLastNameRestriction();
        this.initializeInputEmailValidation();
        this.initializeInputPhoneValidation();
        this.initializeInputZipCodeValidation();
        this.allRadioButtonsEventListener();
        this.initializeInputCommentValidation();
        this.initializePreferredContactValidation();
    }

    allRadioButtonsEventListener(){
        const labels = document.querySelectorAll('.req-call-btn');
        labels.forEach(label => {
            label.addEventListener('click', (event:any) => {
                const label = event.target.closest('.req-call-btn');
                const radio = label.closest('.req-call-tablist').querySelector('.req-call-radio');
                if (radio) {
                    radio.checked = true;
                }
            });
        });
    }

    private initializeInputNameRestriction(): void {
        const inputs = document.querySelectorAll('input.short-form-req-name,#req-name');
        inputs.forEach(input => {
            input.addEventListener('input', this.errorOnName.bind(this));
        });
    }
    private initializeInputLastNameRestriction(): void {
        const inputs = document.querySelectorAll('input.short-form-req-lname,#req-lname');
        inputs.forEach(input => {
            input.addEventListener('input', this.errorOnLastName.bind(this));
        });
    }

    private initializeInputEmailValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-email,#req-email');
        inputs.forEach(input => {
            input.addEventListener('input', this.emailError.bind(this));
        });
    }
    private initializeInputPhoneValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-phone,#req-phone');
        inputs.forEach(input => {
            input.addEventListener('input', this.handleMobileInput.bind(this));
        });
    }
    private initializeInputZipCodeValidation(): void {
        const inputs = document.querySelectorAll('input.short-form-req-address,#req-address');
        inputs.forEach(input => {
            input.addEventListener('input', this.zipCodeError.bind(this));
        });
    }

    private initializeInputCommentValidation(): void {
        const inputs = document.getElementById('short-form-req-comment');
        inputs?.addEventListener('input', this.errorOnComment.bind(this));
    
    }

    private initializePreferredContactValidation(): void {
        const inputs = document.querySelectorAll('input[id*="short-form-req-pref"]');
        inputs.forEach(input => {
            input.addEventListener('input', this.prefMethodError.bind(this));
        });
    }

    public scrollToThankYouSection() {
        if (window.innerWidth <= 768) {
            let targetScrollElement = document.querySelector('.hero-review') as HTMLElement;
            if (targetScrollElement) {
                let OffSetTop = targetScrollElement?.offsetTop;
                window.scrollTo({
                    top: OffSetTop,
                    behavior: 'smooth'
                })
            }
        }
    }

    errorOnComment(event: any) {
        const commentEle = event.target;
        this.form = event.target.closest('form');
        if (commentEle.value !== '' && !REGEX.matchAny.test(commentEle.value)) {
            const getErrMsg = (this.form.querySelector(`#${commentEle?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${commentEle?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid comment.'

            }
        }
        else {
            this.hideError(commentEle?.id)
        }

    }

    prefMethodError(event: any) {
        const ele = event.target;
        this.form = event.target.closest('form');
        const inputs = document.querySelectorAll('input[id*="short-form-req-pref"]');
        const arrChecked:boolean[] = [];
        inputs.forEach((input:any) =>{
            arrChecked.push(input?.checked as boolean);
        });
        const validOutput = arrChecked.some(function(a){
            return a;
        });
        if(validOutput){
            this.hidePrefError('shortform_preferred-contact-error-msg');
        }
        else{
            this.addPrefError('shortform_preferred-contact-error-msg');
        }

    }

    public handleMobileInput(e: any): void {
        const phoneInput = e.target;
        this.form = e.target.closest('form');
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg = (this.form.querySelector(`#${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${phoneInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid phone format.'

            }
            else {
                const field: any = phoneInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid phone format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(phoneInput?.id)
        }

        if (x) {
           phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }
    errorOnName(event: any) {
        const nameInput = event.target;
        this.form = event.target.closest('form');
        if (nameInput.value !== '' && !REGEX.sendName.test(nameInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${nameInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${nameInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid name format.'

            }
            else {
                const field: any = nameInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(nameInput?.id)
        }

    }
    errorOnLastName(event: any) {
        const lastNameInput = event.target;
        this.form = event.target.closest('form');
        if (lastNameInput.value !== '' && !REGEX.sendName.test(lastNameInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${lastNameInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${lastNameInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid last name format.'

            }
            else {
                const field: any = lastNameInput;
                if (field?.parentNode) {
                    const errorMessage = getErrMsg ?? 'Invalid last name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(lastNameInput?.id)
        }

    }

    emailError(event:any) {
        const emailInput = event.target;
        this.form = event.target.closest('form');
        if (emailInput.value != '' && !REGEX.sendEmail.test(emailInput.value)) {
            const getErrMsg = (this.form.querySelector(`#${emailInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${emailInput?.id}-error-msg`);


            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid email address, please try again.'

            }
            else {
                const field: any = emailInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid email address, please try again.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }


        }
        else {

            this.hideError(emailInput?.id)
        }

    }

    zipCodeError(event:any) {
        const addressInput = event.target;
        this.form = event.target.closest('form');
        if ((addressInput?.value != '' && !REGEX.sendZip.test(addressInput?.value.trim())) || addressInput?.value == '00000') {
            const getErrMsg = (this.form.querySelector(`#${addressInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = this.form.querySelector(`#${addressInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else {
                const field: any = addressInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {
            this.hideError(addressInput?.id)
        }

    }

    hideError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        const fieldError: any = this.form.querySelector(`#${id}-error-msg`);
        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }

    hidePrefError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        field?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }
    addPrefError(id: any) {
        const field: any = this.form.querySelector(`#${id}`);
        field?.classList.remove('hidden')
        field?.classList.add('invalid-field');
    }

    private getCheckedPreferedComunnication = () => {

        const checkedCheckboxes = this.form?.querySelectorAll('input[type="checkbox"].checkbox-basic:checked');
        let checkboxString = '';
        checkedCheckboxes?.forEach((checkbox:any, index:any) => {
          const preferredCommunication = checkbox.value;
          checkboxString += capitalizeString(preferredCommunication as any);
          if (index < checkedCheckboxes.length - 1) {
            checkboxString += ',';
          }
        });
    
        return checkboxString;
    
      };

    private getFormFields(): any {

        let residentialDesk = (document.querySelector('input[name="request-call"]') as HTMLInputElement)?.checked;
        const residentialMob = (document.querySelector('input[name="request-call"]') as HTMLInputElement)?.checked;
        const isShortForm = this.form?.closest('.short-form-component');
        const commentsEle =  this.form.querySelector("#short-form-req-comment") as HTMLInputElement;
        let getCheckedPreferedComunnication = this.getCheckedPreferedComunnication();

        if(isShortForm){
            this.nameInput = this.form.querySelector('#short-form-req-name');
            this.lastNameInput = this.form.querySelector('#short-form-req-lname');
            this.emailInput = this.form.querySelector('#short-form-req-email');
            this.phoneInput = this.form.querySelector('#short-form-req-phone');
            this.addressInput = this.form.querySelector('#short-form-req-address');
            residentialDesk =  (isShortForm.querySelector('input[name="request-call"]') as HTMLInputElement)?.checked;
        }

        const fields = {
            nameInput: this.nameInput,
            lastNameInput: this.lastNameInput,
            emailInput: this.emailInput,
            phoneInput: this.phoneInput,
            addressInput: this.addressInput,
            isResidential: residentialDesk ? residentialDesk : residentialMob,
            comments: commentsEle?.value || '',
            preferCommunication: getCheckedPreferedComunnication ||''
        };
  
        return fields;
    }


    private validateInputs(): boolean {

        const formFields = this.getFormFields();
        window.getFormFields = formFields;
        this.form?.querySelectorAll('.invalid-field').forEach((e:any) => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.nameInput?.value === '') {
            errors[formFields.nameInput?.id] = '';
        } else if (!REGEX.sendName.test(formFields.nameInput?.value)) {
            errors[formFields.nameInput?.id] = 'Invalid  name format.';
        }
        if (formFields.lastNameInput?.value === '') {
            errors[formFields.lastNameInput?.id] = '';
        } else if (!REGEX.sendName.test(formFields.lastNameInput?.value)) {
            errors[formFields.lastNameInput?.id] = 'Invalid last name format.';
        }
        if (formFields.phoneInput?.value === '') {
            errors[formFields.phoneInput.id] = '';
        }
        else if (formFields.phoneInput?.value?.length < 14) {
            errors[formFields.phoneInput.id] = 'Invalid phone format.';
        }
        if (formFields.addressInput?.value === '') {
            errors[formFields.addressInput?.id] = '';
        }
        else if (!REGEX.sendZip.test(formFields.addressInput?.value?.trim()) || this.addressInput?.value == '00000') {
            errors[formFields.addressInput?.id] = 'Invalid zip code format.';
        }
        if (formFields.emailInput?.value === '') {
            errors[formFields.emailInput?.id] = '';
        } else if (!REGEX.sendEmail.test(formFields.emailInput?.value)) {
            errors[formFields.emailInput?.id] = 'Invalid email address, please try again.';
        }
        if (formFields.addressInput?.value === '') {
            errors[formFields.addressInput?.id] = '';
        }
        const commentsEle =  this.form.querySelector("#short-form-req-comment") as HTMLInputElement;
        if (commentsEle?.required && formFields.comments === '') {
            errors[commentsEle?.id] = '';
        }
        const labelElement = document.querySelector("#short-form-req-pref-email,#short-form-req-pref-phone,short-form-req-pref-text")?.closest('.input-label-wrap')?.querySelector('.label-basic');
        let isPreferCommunicationRequired = false;
        if (labelElement) {
             isPreferCommunicationRequired = labelElement.hasAttribute('required');
        }
        if (isPreferCommunicationRequired && formFields.preferCommunication === '') {
            errors['shortform_preferred-contact'] = '';
        }
        
        let self = this;
        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = self.form.querySelector(`#${fieldId}`);
            const fieldError: any = self.form.querySelector(`#${fieldId}-error-msg`);
            if(fieldId ==='shortform_preferred-contact'){
                if (fieldError?.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }

    private async handleSubmit(event: any, data: { franchiseId: any; franchiseWebLocationId: any; }, zipcodeInput: any): Promise<void> {
        event.preventDefault();
        const formFields = window.getFormFields ? window.getFormFields : this.getFormFields();
        this.form = window.isShortForm ? window.isShortForm : this.form;
        const campaignName = getCampaignName();
        const addressZip: any = zipcodeInput;
        var host = window.location.hostname.toLowerCase();
        let IsTest;
        if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) {
            IsTest = true;
        } else {
            IsTest = false;
        }
        const id: any = data.franchiseWebLocationId ?? undefined;
        const franchiseId: any = data.franchiseId ?? undefined;
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const defaultCustomerType = (document.getElementById('defaultCustomerType') as HTMLInputElement)?.value;
        const url = window.location.origin;
        let vendorId: any;
        let vendorName: any;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const lastName = formFields.lastNameInput?.value ?? '';
        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;

        const requestBody: any = {
            IsLocalized: true,
            FirstName: formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
            LastName: formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
            ZipCode: addressZip?.address?.postalCode.trim(),
            PostalCode: addressZip?.address?.postalCode.trim(),
            Email: formFields.emailInput?.value,
            State: addressZip?.address?.state,
            City: addressZip?.address?.city,
            Phone: formFields.phoneInput?.value,
            Address: "No address collected",
            Address2: '',
            IsNewCustomer: "",
            Comments: formFields?.comments || '',
            Note: formFields.isResidential ? 'Residential' : 'Commercial',
            SignUpForUpdates: '',
            ConceptId: Number(conceptId),
            ConceptCode: conceptCode,
            LeadSource: "WEB",
            LeadOrigin: "WEB",
            PhoneType: true,
            IsLeadOnly: true,
            IsTest: IsTest,
            ScheduleID: "",
            CampaignName: campaignName,
            CallType: "WEB",
            ConceptCalledId: Number(conceptId),
            IsEstimate: false,
            EmailOptOut: emailOptOut,
            VendorId: vendorId,
            VendorName: vendorName,
            CurrentPage: url,
            CustomerType: formFields.isResidential ? 'Residential' : 'Commercial',
            PreferredCommunicationType: formFields?.preferCommunication || 'Email,Phone',
            LeadSourceID: defaultLeadSourceId
        };

        if (defaultCustomerType) {
            requestBody.CustomerType = defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
            requestBody.Note = defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
        }
        if (id) {
            requestBody.FranchiseWebLocationId = id;
            requestBody.WebLocationId = id;
            requestBody.FranchiseId = franchiseId;

            const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');

            if (dbaName && brandData?.send_shortform_lead_bookingapi) {
                const zipCode:any = sessionStorage.getItem('sAddressParam');
                const webId = (document.getElementById('weblocationId') as HTMLInputElement)?.value ?? localStorage.getItem('weblocationId');
                if (zipCode) {
                    let franchiseIdDetails = await getFranchiseWebLocationId(zipCode) as any
                    requestBody.FranchiseWebLocationId = webId ?? id;
                    requestBody.WebLocationId = webId ?? id;
                    requestBody.FranchiseId = franchiseIdDetails ? franchiseIdDetails?.franchiseId : null;
                }else if(webId){
                    requestBody.FranchiseWebLocationId = webId;
                    requestBody.WebLocationId = webId;
                    const apiUrl = apiConfig.FranchisePOSLookup+webId;
                    try{
                        let franchiseIdPOSDetails = await getCachedAPIResponse(apiUrl, apiCaching.POSSystemLookup);
                        if(franchiseIdPOSDetails.length > 0){
                            requestBody.FranchiseId = franchiseIdPOSDetails[0]?.franchiseId ? franchiseIdPOSDetails[0]?.franchiseId : null;
                        }
                    }
                    catch{
                        console.error("Error fetching FranchisePOSLookup in Residential Forms")
                    }
                }
            }
        }

        if (lastName) {
            requestBody.LastName = lastName;
            requestBody.FirstName = formFields.nameInput?.value ?? '';
        }

        try {
            if (conceptCode?.toLocaleLowerCase() === 'dvw' && franchiseId) {
                const dynamic_url = `${apiConfig.howToKnow}?apikey=${process.env.JS_API_KEY}&franchiseId=${franchiseId}`;
                const request = {
                    method: 'GET',
                    url: dynamic_url,
                };

                const leadSources: LeadSourceArray = await apiRequest(request);
                const othersLeadSource: LeadSource | undefined = leadSources.find(
                    (source: LeadSource) => source?.leadSourceName.toLocaleLowerCase() === "others"
                );

                if (othersLeadSource?.referralTypeId) {
                    requestBody.ReferralTypeId = othersLeadSource.referralTypeId;
                }
                if (othersLeadSource?.leadSourceName) {
                    requestBody.ReferralType = othersLeadSource.leadSourceName;
                }

            }
        } catch (error) {
            console.error('An error occurred in generic-lead-sources API in ResidentialForm.ts file:', error);
        }

        const request = {
            method: 'POST',
            url: apiConfig.BOOKING_API_URL,
            data: requestBody
        };
        const isShortForm = this.form?.closest('.short-form-component');
        const tabBodyDiv = this.form?.closest('.tab-body');

        let formDiv = tabBodyDiv.querySelector('.residential-form-container') as HTMLFormElement;
        let formDivHeader = tabBodyDiv.querySelector('.form-heading-container') as HTMLFormElement;
        let thankYouHeader = tabBodyDiv.querySelector('#thank-you-header') as HTMLFormElement;
        let thankYouDesc = tabBodyDiv.querySelector('#thank-you-description') as HTMLFormElement;
        let errorMsg = tabBodyDiv.querySelector('#error--header') as HTMLFormElement;
        let eroorDesc = tabBodyDiv.querySelector('#error-description') as HTMLFormElement;

        if(isShortForm){
            const currentSectionDom = this.form?.closest('.short-form-component') 
             formDiv = currentSectionDom.querySelector('.residential-form-container') as HTMLFormElement;
             formDivHeader = currentSectionDom.querySelector('.form-heading-container') as HTMLFormElement;
             thankYouHeader = currentSectionDom.querySelector('#shortform-thank-you-header') as HTMLFormElement;
             thankYouDesc = currentSectionDom.querySelector('#shortform-thank-you-description') as HTMLFormElement;
             errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
             eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
        }

        apiRequest(request)
            .then((response: any) => {
                stopLoader()
                storeBookingApiPayload(requestBody, response);
                formDiv.remove();
                formDivHeader?.remove();
                thankYouHeader?.classList.remove("hidden");
                thankYouDesc?.classList.remove("hidden");
                this.scrollToThankYouSection();
                const closeModal = document.getElementById('close-local-modal');
                if (closeModal) {
                    closeModal.click();
                }
                insertAfterUrl();
                this.createHiddenLocalWeblocalIdInput(id);
            })
            .catch((err) => {
                stopLoader();
                errorMsg?.classList.remove("hidden");
                eroorDesc?.classList.remove("hidden");
                formDiv?.remove();
                formDivHeader?.remove();
            });

    }

    private setAutoSuggested = (e: any) => {
        this.form = (e.target as HTMLElement).closest('form');
        window.isShortForm = this.form;

        e.preventDefault()
        if (this.validateInputs()) {
            const formFields = this.getFormFields();
            const addressId = formFields?.addressInput?.id;
            const request = {
                method: 'GET',
                url: `${apiConfig.DISCOVER_API}?q=${(this.form.querySelector(`#${addressId}`) as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                    if (result?.items.length > 0) {
                        this.privateSuggestedArray = result?.items
                        const zipcodeData = result?.items[0]
                        const updatedData = this.getAddress(zipcodeData);
                        this.getFranchiseWebLocationId(e, updatedData);
                    } else {
                        this.handleErrorcase(2);
                    }
                })
                .catch((err) => {
                    this.handleErrorcase(1);
                });
        }
    }

    private getAddress(data: any) {
        let newData = data;
        newData.title = "No address collected";
        return newData;
    }

    private handleErrorcase(inputArg = 0) {
        let errorMsg = document.getElementById('error--header') as HTMLFormElement;
        let eroorDesc = document.getElementById('error-description') as HTMLFormElement;
        let isShortForm = this.form?.closest('.short-form-component');
        if(isShortForm) {
            const formHeading = isShortForm.querySelector(".form-heading-container") as HTMLFormElement;
            const formContainer = isShortForm.querySelector('div[class*="form-container"]') as HTMLFormElement;
            errorMsg = isShortForm.querySelector('#shortform-error--header') as HTMLFormElement;
            eroorDesc = isShortForm.querySelector('#shortform-error-description') as HTMLFormElement;
            const noService = isShortForm.querySelector('#shortform-no-service-header') as HTMLFormElement;
            const noServiceDesc = isShortForm.querySelector('#shortform-no-service-description') as HTMLFormElement;
            formHeading.classList.add("hidden");
            formContainer.classList.add("hidden");
            if(inputArg === 1){
                errorMsg.classList.remove("hidden");
                eroorDesc.classList.remove("hidden");
                noService.classList.add("hidden");
                noServiceDesc.classList.add("hidden");
            }
            else{
                errorMsg.classList.add("hidden");
                eroorDesc.classList.add("hidden");
                noService.classList.remove("hidden");
                noServiceDesc.classList.remove("hidden");
            }
        }
        else{
            errorMsg.classList?.remove("hidden");
            eroorDesc.classList?.remove("hidden");
            const html = `<div class="tab-body">
            <h2 class="form-heading">${messageData?.lead_form_noservice_title}</h2>
            <p class="thank-text">${messageData?.lead_form_noservice_description}</p>
            </div>`;
            let messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
            let formDiv = document.querySelector('.tab-body') as HTMLFormElement;
            formDiv?.remove()
            messageDiv?.insertAdjacentHTML('beforeend', html);
            this.scrollToThankYouSection();
        }
        stopLoader();
    }

    private asideCardToMobile() {
        const asideCard = document.querySelector('.aside-card.tabs') as HTMLElement;
        const asideCardMobile = document.querySelector('.aside-card.tabs-mobile') as HTMLElement;

        try {

            if (asideCard && asideCardMobile) {
                if (window.innerWidth <= 768) {
                    while (asideCard.firstChild) {
                        asideCardMobile.appendChild(asideCard.firstChild);
                    }
                } else {
                    while (asideCardMobile.firstChild) {
                        asideCard.appendChild(asideCardMobile.firstChild);
                    }
                }
            }

        } catch (error) {
            console.log("Error in Residential form: ", error);
        }

    }

    private getFranchiseWebLocationId(event: Event, zipcodeInput: any) {
        event.preventDefault();
        const formFields = this.getFormFields();
        const zipCode: any = zipcodeInput;
        const tabBodyDiv = this.form?.closest('.tab-body');
        let errorMsg = tabBodyDiv.querySelector('#error--header') as HTMLFormElement;
        let eroorDesc = tabBodyDiv.querySelector('#error-description') as HTMLFormElement;
        let isShortForm = this.form?.closest('.short-form-component');

        if(isShortForm){
            const currentSectionDom = this.form?.closest('.short-form-component') 
             errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
             eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
        }
        
        if (this.validateInputs()) {
            startLoader()
            const apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(zipCode?.address?.postalCode));
            axios.get(apiUrl, {
                headers: {
                    'Cache-Control': 'max-age=0',
                },
            }).then(async (response: any) => {
                    const result = response.data;
                    const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                    const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
                    if (result.length > 0) {
                        const obj = {
                            'franchiseId': result[0].franchiseId,
                            'franchiseWebLocationId': result[0].franchiseWebLocationId
                        }

                        this.handleSubmit(event, obj, zipcodeInput);
                    }
                    else {
                        const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');
                        if(!brandData?.send_shortform_lead_bookingapi || !dbaName){

                        if (!brandData?.lead_contactapi) {
                            errorMsg.classList.remove("hidden");
                            eroorDesc.classList.remove("hidden");
                            let formDivResidential = document.querySelector('.residential-form-container') as HTMLFormElement;
                            let formDivHeader = document.querySelector('.form-heading-container') as HTMLFormElement;
                            if(isShortForm){
                                const currentSectionDom = this.form?.closest('.short-form-component');
                                formDivResidential = currentSectionDom.querySelector('.residential-form-container') as HTMLFormElement;
                                formDivHeader = currentSectionDom.querySelector('.form-heading-container') as HTMLFormElement;
                            }

                            formDivResidential?.remove();
                            formDivHeader?.remove();
                            const html = `<div class="tab-body">
                    <h2 class="form-heading">${messageData?.lead_form_noservice_title}</h2>
                    <p class="thank-text">${messageData?.lead_form_noservice_description}</p>
                </div>`;
                            let messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                            let formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                            if(isShortForm){
                                const currentSectionDom = this.form?.closest('.short-form-component');
                                messageDiv = currentSectionDom.querySelector('.tabs-wrapper') as HTMLFormElement;
                                formDiv = currentSectionDom.querySelector('.tab-body') as HTMLFormElement;
                            }
                            messageDiv?.insertAdjacentHTML('beforeend', html);
                            formDiv?.remove();
                            this.scrollToThankYouSection();

                        } else {
                            this.contactUsApiCall(zipcodeInput);
                        }
                    }else {
                        const obj = {
                            'franchiseId':null,
                            'franchiseWebLocationId': localStorage.getItem('weblocationId')
                        }
                        this.handleSubmit(event, obj, zipcodeInput);
                    }
                        stopLoader()
                    }
                })
                .catch(async (error) => {
                    stopLoader();
                    const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                    const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

                    const duplicate = error?.response?.data;
                    const step1 = document.querySelector('.step-1') as HTMLElement;
                    if (step1) {
                        step1.classList.add('hidden');
                    }
                    if (duplicate?.Message === 'Full address is needed to narrow down results') {
                        const stateHandler = new StateHandler();
                        stateHandler.getStateFun();
                        const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
                        findLocalBtn?.click();
                        const step2 = document.querySelector('.step-2') as HTMLElement;
                        if (step2) {
                            step2.classList.remove('hidden');
                            step2.classList.add('block');

                            const closeLocalModal = document.querySelector('.close-modal-btn');
                            closeLocalModal?.addEventListener('click', function handleClick(event) {
                                step2.classList.add("hidden");
                                step1.classList.remove("hidden");
                            });
                        }
                        const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;

                        modalZipCode.value = formFields.addressInput?.value;
                        sessionStorage.setItem('shortFormAddressBumper', 'true');

                        const locateAddressBumper = new LocateAddressBumper();
                    } else {
                        if (!brandData?.lead_contactapi) {
                            const html = `<div class="tab-body">
                                <h2 class="form-heading">${messageData?.lead_form_error_title}</h2>
                                <p class="thank-text">${messageData?.lead_form_error_description}</p>
                                </div>`;
                            let messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                            let formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                            if(isShortForm){
                                const currentSectionDom = this.form?.closest('.short-form-component');
                                messageDiv = currentSectionDom.querySelector('.tabs-wrapper') as HTMLFormElement;
                                formDiv = currentSectionDom.querySelector('.tab-body') as HTMLFormElement;
                            }
                            formDiv.remove()
                            messageDiv?.insertAdjacentHTML('beforeend', html);
                        } else {
                            this.contactUsApiCall(zipcodeInput);
                        }
                    }

                });
        }
    }

    async contactUsApiCall(zipcodeInput: any) {

        const formFields = this.getFormFields();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="req-address"]') as HTMLElement)?.innerText;
        const lastName = formFields.lastNameInput?.value ?? '';
        const addressZip: any = zipcodeInput;

        let formDiv = document.querySelector('.residential-form-container') as HTMLFormElement;
        let formDivHeader = document.querySelector('.form-heading-container') as HTMLFormElement;
        let thankYouHeader = document.getElementById('thank-you-header') as HTMLFormElement;
        let thankYouDesc = document.getElementById('thank-you-description') as HTMLFormElement;
        let errorMsg = document.getElementById('error--header') as HTMLFormElement;
        let eroorDesc = document.getElementById('error-description') as HTMLFormElement;
        const isShortForm = this.form?.closest('.short-form-component');

        if(isShortForm){
            const currentSectionDom = this.form?.closest('.short-form-component') 
             formDiv = currentSectionDom.querySelector('.residential-form-container') as HTMLFormElement;
             formDivHeader = currentSectionDom.querySelector('.form-heading-container') as HTMLFormElement;
             thankYouHeader = currentSectionDom.querySelector('#shortform-thank-you-header') as HTMLFormElement;
             thankYouDesc = currentSectionDom.querySelector('#shortform-thank-you-description') as HTMLFormElement;
             errorMsg = currentSectionDom.querySelector('#shortform-error--header') as HTMLFormElement;
             eroorDesc = currentSectionDom.querySelector('#shortform-error-description') as HTMLFormElement;
        }

        let vendorId;
        let vendorName;

        try {

            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

            if (brandData.lead_contactapi) {

                if (brandData) {
                    vendorId = brandData.vendorId;
                    vendorName = brandData.vendorName;
                }

                const requestBody = {
                    "FirstName": formFields.nameInput?.value.split(' ')[0] ? formFields.nameInput?.value.split(' ')[0] : '',
                    "LastName": formFields.nameInput?.value.split(' ')[1] ? formFields.nameInput?.value.split(' ')[1] : ' ',
                    "ZipCode": addressZip?.address?.postalCode.trim(),
                    "Email": formFields.emailInput?.value,
                    "State": addressZip?.address?.state,
                    "City": addressZip?.address?.city,
                    "Phone": formFields.phoneInput?.value,
                    "Address": addressZip?.address?.label,
                    "address2": '',
                    "country": addressZip?.address?.country,
                    "Comments": zipCodeText,
                    "SignUpForUpdates": false,
                    "IsLocalized": false,
                    "IsNewCustomer": null,
                    "IsTest": true,
                    "ConceptId": conceptId?.value,
                    "VendorId": vendorId,
                    "VendorName": vendorName
                };
                const host = window.location.hostname.toLowerCase();
                if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
                    requestBody.IsTest = true;
                } else {
                    requestBody.IsTest = false;
                }
                if (lastName) {
                    requestBody.LastName = lastName;
                    requestBody.FirstName = formFields.nameInput?.value ?? '';
                }

                const request = {
                    method: 'POST',
                    url: apiConfig.CONTACT_US_SEND,
                    data: requestBody
                };

                await apiRequest(request);
                formDiv?.remove();
                formDivHeader?.remove();
                if (thankYouHeader) {
                    thankYouHeader.classList.remove("hidden");
                    thankYouDesc.classList.remove("hidden");
                }
                this.scrollToThankYouSection();
            }
        }
        catch (error) {
            console.error(`Error in Residential.ts file in the contactUsApiCall function. Error message: ${error}`);
            errorMsg?.classList.remove("hidden");
            eroorDesc?.classList.remove("hidden");
            formDiv.remove();
            formDivHeader.remove();
        }
    }

    public createHiddenLocalWeblocalIdInput(id: any): void {

        const localWebLocationIdInput: HTMLInputElement = document.createElement('input');
        localWebLocationIdInput.type = 'hidden';
        localWebLocationIdInput.value = id;
        localWebLocationIdInput.id = 'local_weblocationId';
        document.body.appendChild(localWebLocationIdInput);

    }
}

const residentialForm = new ResidentialForm();
