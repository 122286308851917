import { REGEX } from "../constants/regex";
export function validateForm(form:any){
    let validationFlag = true;
    // Validating input fields
    const inputFields = form.querySelectorAll('input');
    const isCheckEmpty = true;
    inputFields.forEach((el:any)=>{
        handleInput(el,isCheckEmpty);
    });
    // Validating checkboxes
    const preferMethods = form.querySelectorAll('.referred-check-wrap input[type="checkbox"]');
    handleCheckboxInput(preferMethods);
    validateSqrft();
    // Checking if any errors
    const items = form.querySelectorAll('span.error-msg');
    items.forEach((item:any) => {
        if(!item.classList.contains("hidden")){
            validationFlag = false;
        }
    });
    return validationFlag;
}

function isBlank(str:string,isCheckEmpty:any){
    const whitespace = /^\s*$/;
    return isCheckEmpty ?(!str || whitespace.test(str)) : false;    // Check if falsey or whitespace
}
function emailValidation(elem:any,isCheckEmpty:any){
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if(elem.required){
        if(isBlank(elem.value,isCheckEmpty)){
            errorMsg(elem,true);
        } else if((elem.value && !emailRegex.test(elem.value))){
            const errorMsgEle = document.getElementById(`${elem.name}-error-msg`) as HTMLElement;
            if(errorMsgEle){
                errorMsgEle.innerHTML = 'Invalid email format.';
                errorMsgEle.classList.remove("hidden");
            }
            errorMsg(elem,true);
        }
        else{
            errorMsg(elem,false);
        }
    } 
}
function textValidation(elem:any,isCheckEmpty:any){
    const textRegex = REGEX.sendName;
    if(elem.required){
        if(isBlank(elem.value,isCheckEmpty)){
            errorMsg(elem,true);
        } else if(elem.value && !textRegex.test(elem.value)){
            const errorMsg = document.getElementById(`${elem.name}-error-msg`) as HTMLElement;
            if(errorMsg){
                if(elem.name == 'mly-user-fname'){
                errorMsg.innerHTML = 'Invalid name format.';
                }else if(elem.name == 'mly-user-lname'){
                    errorMsg.innerHTML = 'Invalid last name format.';
                }
                errorMsg.classList.remove("hidden");
            }
        }
        else{
            errorMsg(elem,false);
        }
    } 
}
function numberValidation(elem:any,isCheckEmpty:any){
    const numRegex = REGEX.allow1to99999Digit;
    if(elem.required && isBlank(elem.value,isCheckEmpty)){
        errorMsg(elem,true);
    }
    else{
        elem.value = elem.value?.replace(/\D+/g, '')
        if(elem.value){
            const x = elem.value.match(numRegex);
            if(x){
                elem!.value = x[0];
                errorMsg(elem,false);
            }
            else{
                errorMsg(elem,true);
            }
        }
        
    }
}
function phoneValidation(elem:any,isCheckEmpty:any){
    const phoneInput = elem;
    const numRegex = REGEX.mobileNumberRegex;
    const x = elem.value?.replace(/\D/g, '').match(numRegex);
    const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
    if(elem.required && isBlank(elem.value,isCheckEmpty)){
        errorMsg(elem,true);
    } else if(elem.value && phoneInput?.value.length < 14 && formattedVal.length < 14){
        const errorMsgEle = document.getElementById(`${elem.name}-error-msg`) as HTMLElement;
        if(errorMsgEle){
            errorMsgEle.innerHTML = 'Invalid phone number format.';
            errorMsgEle.classList.remove("hidden");
        }
        errorMsg(elem,true);
    }
    else{
        if(x){
            elem!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }
        errorMsg(elem,false);
    }

        
}
function zipCodeValidation(elem:any,isCheckEmpty:any){
    const zipRegex = REGEX.sendZip;
    if(elem.required){
        if(isBlank(elem.value,isCheckEmpty)){
            errorMsg(elem,true);
        }else if((elem.value && !zipRegex.test(elem.value)) || elem.value == '00000'){
            const errorMsgEle = document.getElementById(`${elem.name}-error-msg`) as HTMLElement;
            if(errorMsgEle){
                errorMsgEle.innerHTML = 'Invalid zip code format.';
                errorMsgEle.classList.remove("hidden");
            }
            errorMsg(elem,true);
        }
        else{
            errorMsg(elem,false);
        }
    } 
}
function AddrValidation(elem:any,isCheckEmpty:any){
    const addrRegex = /^[^!?@#$%^&*()]*$/;
    if((elem.required && isBlank(elem.value,isCheckEmpty))){
        errorMsg(elem,true);
    }else if(elem.value && !addrRegex.test(elem.value)){
        const errorMsgEle = document.getElementById(`${elem.name}-error-msg`) as HTMLElement;
        if(errorMsgEle){
            errorMsgEle.innerHTML = 'Invalid address format.';
            errorMsgEle.classList.remove("hidden");
        }
        errorMsg(elem,true);
    }
    else{
        errorMsg(elem,false);
    }
}
function Addr2Validation(elem:any,isCheckEmpty:any){
    const addrRegex = /^[^!?@#$%^&*()]*$/;
    if(!addrRegex.test(elem.value)){
        errorMsg(elem,true);
    } 
    else{
        errorMsg(elem,false);
    }
}

function errorMsg(elem:any, flag:boolean){
    if(flag){
        document.getElementById(`${elem.name}-error-msg`)?.classList.remove("hidden");
    }
    else{
        document.getElementById(`${elem.name}-error-msg`)?.classList.add("hidden");
    }
}

export function handleInput(el:any, isCheckEmpty:any){
    switch (el.type) {
        case "text":
            switch (el.name) {
                case 'mly-user-number':
                    phoneValidation(el,isCheckEmpty);
                    break;
                case 'mly-user-address':
                    AddrValidation(el,isCheckEmpty);
                    break;
                case 'mly-user-address-2':
                    Addr2Validation(el,isCheckEmpty);
                    break;
                case 'mly-user-zip-code':
                    zipCodeValidation(el,isCheckEmpty);
                    break;
                case 'mly-user-fname':
                    textValidation(el,isCheckEmpty);
                    break;
                case 'mly-user-lname':
                    textValidation(el,isCheckEmpty);
                    break;
            }
            break;
        case "email":
            emailValidation(el,isCheckEmpty);
            break;
        case "number":
            numberValidation(el,isCheckEmpty);
            break;
        default:
            break;
    }
}

export function handleCheckboxInput(preferMethods:any){
    let counter = 0;
    preferMethods.forEach((el:any)=>{
        if(!el.checked){
            counter = counter+1;
        }
    });
    if(counter == preferMethods.length){
        document.getElementById('preferred-contact-error-msg')?.classList.remove("hidden");
    }
    else{
        document.getElementById('preferred-contact-error-msg')?.classList.add("hidden");
    }
}

export function validateSqrft(){
    const resTag = document.getElementById('cleaning-residential') as HTMLInputElement;
    const commTag = document.getElementById('cleaning-commercial') as HTMLInputElement;
    const freqTagRes = document.getElementById('frequency-cleaning-one') as HTMLInputElement;
    const freqTagComm = document.getElementById('commercial-frequency-cleaning-recurring') as HTMLInputElement;
    let inputTag:any;
    if(resTag.checked && freqTagRes.checked){
        inputTag = document.getElementById('home-detail-sqrft') as HTMLInputElement;
    }
    else if(commTag.checked && freqTagComm.checked){
        inputTag = document.getElementById('office-detail-sqrft') as HTMLInputElement;
    }
    if(inputTag){
        numberValidation(inputTag,true);
    }
    else{
        errorMsg(document.getElementById('home-detail-sqrft') ,false);
        errorMsg(document.getElementById('office-detail-sqrft') ,false);
    }
    
}
