import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { commonStates, getBrandDetailsFromBrandJson, getCampaignName, getFranchiseWebLocationId, removeAllEventListeners } from "../util/share";

const errorMessage = {
    fname: "Invalid name format",
    lname: "Invalid last name format",
    email: "Invalid email format",
    phone: "Invalid phone format",
    zip: "Invalid zip code",
    city: "Invalid city",
    state: "Invalid state",
    address: "Invalid address",
    address2: "Invalid address2"
}

export class FinancesService {

    public financeBtn: any;
    public financeFirstName: any;
    public financeLastName: any;
    public financeEmail: any;
    public financePhone: any;
    public financeZip: any;
    public financeCity: any;
    public financeState: any;
    public financeAddress: any;
    public financeAddress2: any;

    constructor() {
        this.initFinanceInputs();
        this.fetchAllState();
    }

    public initFinanceInputs() {

        this.financeBtn = removeAllEventListeners('#financing-form .primary-btn');
        this.financeFirstName = document.getElementById('finance-fname');
        this.financeLastName = document.getElementById('finance-lname');
        this.financeEmail = document.getElementById('finance-email');
        this.financePhone = document.getElementById('finance-phone');
        this.financeZip = document.getElementById('finance-zip');
        this.financeCity = document.getElementById('finance-city');
        this.financeState = document.getElementById('finance-state');
        this.financeAddress = document.getElementById('finance-address');
        this.financeAddress2 = document.getElementById('finance-address2');
        try {


            if (this.financeBtn) {
                this.financeBtn.addEventListener("click", this.handleFinanceClick.bind(this));
            }

            if (this.financeFirstName) {
                this.financeFirstName.addEventListener('input', () => this.handleCommonInputError(this.financeFirstName, `${this.financeFirstName.id}-error-msg`, REGEX.sendName, errorMessage.fname));
            }

            if (this.financeLastName) {
                this.financeLastName.addEventListener('input', () => this.handleCommonInputError(this.financeLastName, `${this.financeLastName.id}-error-msg`, REGEX.sendName, errorMessage.lname));
            }

            if (this.financeEmail) {
                this.financeEmail.addEventListener('input', () => this.handleCommonInputError(this.financeEmail, `${this.financeEmail.id}-error-msg`, REGEX.sendEmail, errorMessage.email));
            }

            if (this.financePhone) {
                this.financePhone.addEventListener('input', this.handleMobileInput.bind(this));
            }

            if (this.financeZip) {
                this.financeZip.addEventListener('input', () => this.handleCommonInputError(this.financeZip, `${this.financeZip.id}-error-msg`, REGEX.sendZip, errorMessage.zip));
            }

            if (this.financeCity) {
                this.financeCity.addEventListener('input', () => this.handleCommonInputError(this.financeCity, `${this.financeCity.id}-error-msg`, REGEX.sendCity, errorMessage.city));
            }

            if (this.financeAddress) {
                this.financeAddress.addEventListener('input', () => this.handleCommonInputError(this.financeAddress, `${this.financeAddress.id}-error-msg`, REGEX.sendAddress1, errorMessage.address));
            }

            if (this.financeAddress2) {
                this.financeAddress2.addEventListener('input', () => this.handleCommonInputError(this.financeAddress2, `${this.financeAddress2.id}-error-msg`, REGEX.sendAddress2, errorMessage.address2));
            }

            if (this.financeState) {
                this.financeState.addEventListener('change', () => this.handleCommonInputError(this.financeState, `${this.financeState.id}-error-msg`, REGEX.sendName, errorMessage.state));
            }

            const financeConfirmationBookingData = sessionStorage.getItem('financeConfirmationBookingData');
            if (financeConfirmationBookingData) {
                this.confirmBookingData();
            }

        } catch (error) {
            console.error('Error in initializing finance inputs:', error);

        }
    }

    public handleMobileInput(e: any): void {
        const phoneInput = e.target;
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg = (document.getElementById(`${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`${phoneInput?.id}-error-msg`);
            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? errorMessage.phone;
            }
        }
        else {
            this.hideError(phoneInput?.id)
        }

        if (x) {
            phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    hideError(id: any) {
        const field: any = document.getElementById(`${id}`);
        const fieldError: any = document.getElementById(`${id}-error-msg`);
        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field');
    }

    public handleCommonInputError(inputElement: any, errorId: any, validationRegex: any, errorMessage: any) {
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement.value)) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = getErrMsg ?? errorMessage;
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    public async handleFinanceClick() {
        console.log('Finance button clicked');
        if (this.validateFields()) {
            startLoader();
            console.log('All fields are valid');
            try {
                const brandData = await this.getBrandData();
                const zipCode = this.financeZip?.value;
                const getFranchiseWebLocationIdDetails = await getFranchiseWebLocationId(zipCode);
                if (getFranchiseWebLocationIdDetails) {
                    const requestBody = this.createRequestBody(brandData, getFranchiseWebLocationIdDetails);
                    const financeConfirmationBookingData = {
                        "FirstName": requestBody.FirstName,
                        "LastName": requestBody.LastName,
                        "ZipCode": requestBody.ZipCode,
                        "Email": requestBody.Email,
                        "State": requestBody.State,
                        "City": requestBody.City,
                        "Phone": requestBody.Phone,
                        "Address": requestBody.Address,
                        "address2": requestBody.address2,
                    };

                    sessionStorage.setItem('financeConfirmationBookingData', JSON.stringify(financeConfirmationBookingData));
                    await this.sendRequest(requestBody);
                    this.showConfirmation();
                } else {
                    this.hideForm();
                    this.showNoService();
                }
                stopLoader();

            } catch (error) {
                console.error(`Error in finace.ts file in the handleFinanceClick function. Error message: ${error}`);
                this.hideForm();
                this.showFailure();
                stopLoader();
            }
        }
    }

    public async getBrandData() {
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        return await getBrandDetailsFromBrandJson(conceptCode?.value);
    }

    public createRequestBody(brandData: any, getFranchiseWebLocationIdDetails: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const campaignName = getCampaignName();

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;
        const url = window.location.origin;
        let vendorId: any;
        let vendorName: any;
        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }
        const lastName = this.financeLastName?.value ?? '';

        const host = window.location.hostname.toLowerCase();
        let IsTest = false
        if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) {
            IsTest = true;
        }

        const requestBody: any = {
            "FirstName": this.financeFirstName?.value.split(' ')[0] ? this.financeFirstName?.value.split(' ')[0] : '',
            "LastName": this.financeLastName?.value.split(' ')[1] ? this.financeLastName?.value.split(' ')[1] : ' ',
            "ZipCode": this.financeZip?.value || '',
            "Email": this.financeEmail?.value || '',
            "State": this.financeState?.value || '',
            "City": this.financeCity?.value || '',
            "Phone": this.financePhone?.value || '',
            "Address": this.financeAddress?.value || '',
            "address2": this.financeAddress2?.value || '',
            "IsNewCustomer": "",
            "Comments": '',
            "Note": 'Finance From',
            "SignUpForUpdates": '',
            "ConceptId": Number(conceptId),
            "ConceptCode": conceptCode,
            "LeadSource": "WEB",
            "LeadOrigin": "WEB",
            "PhoneType": true,
            "IsLeadOnly": true,
            "IsTest": IsTest,
            "ScheduleID": "",
            "CampaignName": campaignName,
            "CallType": "WEB",
            "ConceptCalledId": Number(conceptId),
            "IsEstimate": false,
            "EmailOptOut": emailOptOut,
            "VendorId": vendorId,
            "VendorName": vendorName,
            "CurrentPage": url,
            "CustomerType": 'Residential',
            "PreferredCommunicationType": 'Email,Phone',
            "LeadSourceID": defaultLeadSourceId
        };

        if (lastName) {
            requestBody.LastName = lastName;
            requestBody.FirstName = this.financeFirstName?.value ?? '';
        }

        if (getFranchiseWebLocationIdDetails) {
            requestBody.FranchiseWebLocationId = getFranchiseWebLocationIdDetails?.franchiseWebLocationId;
            requestBody.WebLocationId = getFranchiseWebLocationIdDetails?.franchiseWebLocationId;
            requestBody.FranchiseId = getFranchiseWebLocationIdDetails?.franchiseId;
        }

        return requestBody;
    }

    public async sendRequest(requestBody: any) {
        const request = {
            method: 'POST',
            url: apiConfig.BOOKING_API_URL,
            data: requestBody
        };
        await apiRequest(request);
    }

    public showConfirmation() {
        const confimationRedirect = (document.getElementById('finance-redirect') as HTMLInputElement)?.value;
        if (confimationRedirect) {
            window.location.href = confimationRedirect;
        }
    }

    public hideForm() {
        const forms = document.getElementById('financing-form');
        if (forms) {
            forms.parentElement?.classList.add('hidden');
        }
    }

    public showFailure() {
        const cont = document.getElementById('failure');
        if (cont) cont.classList.remove('hidden');
    }

    public showNoService() {
        const cont = document.getElementById('noservice');
        if (cont) cont.classList.remove('hidden');
    }

    public validateFields() {
        document.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
        const errors: any = {};

        const validateField = (field: HTMLInputElement, regex: RegExp, errorMessage: string) => {
            if (field?.value === '') {
                errors[field?.id] = '';
            } else if (field?.value !== '' && !regex.test(field?.value)) {
                errors[field?.id] = errorMessage;
            } else if(field?.value && field?.id =='finance-phone' && field?.value.length < 14){
                errors[field?.id] = errorMessage;
            }
        };

        validateField(this.financeFirstName, REGEX.sendName, errorMessage.fname);
        validateField(this.financeLastName, REGEX.sendName, errorMessage.lname);
        validateField(this.financePhone, REGEX.mobileNumberRegex, errorMessage.phone);
        validateField(this.financeAddress, REGEX.sendAddress1, errorMessage.address);
        validateField(this.financeEmail, REGEX.sendEmail, errorMessage.email);
        validateField(this.financeZip, REGEX.sendZip, errorMessage.zip);
        validateField(this.financeState, REGEX.sendName, errorMessage.state);
        validateField(this.financeCity, REGEX.sendCity, errorMessage.city);
        // validateField(this.financeAddress2, REGEX.sendAddress1, errorMessage.address);

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(`${fieldId}`);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                if (fieldError?.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }

    async fetchAllState() {
        try {
            const state_us: any[] = await commonStates('US');
            const state_ca: any[] = await commonStates('CA');
            let finalResult = [...state_us, ...state_ca];
            finalResult.sort((a, b) => {
                if (a.stateName !== b.stateName) {
                    return a.stateName < b.stateName ? -1 : 1;
                } else {
                    return 0;
                }
            });
            this.createCommonState(finalResult);
            return finalResult;
        }
        catch (error) {
            console.error('Error in fetching state API data:', error);
        }
    }

    public createCommonState(finalResult: any[]) {
        const selectElement = document.getElementById('finance-state');
        if (selectElement) {
            selectElement.innerHTML = '<option selected="" value="">Select</option>';
        }

        finalResult.forEach(state => {
            const option = document.createElement('option');
            option.value = state.stateCode;
            option.textContent = state.stateName;
            if (selectElement) {
                selectElement.appendChild(option);
            }
        });
    }

    public confirmBookingData() {
        const financeConfirmationBookingData = sessionStorage.getItem('financeConfirmationBookingData');
        const financeConfirmation = document.getElementById('finance-confirmation');
        if (financeConfirmationBookingData && financeConfirmation) {
            const data = JSON.parse(financeConfirmationBookingData);
            this.updateConfirmationName(data);
            this.updateConfirmationEmail(data);
            this.updateConfirmationPhone(data);
            this.updateConfirmationAddress(data);
        }
    }

    private updateConfirmationName(data: any) {
        const nameDom = document.getElementById('finance_confirmation_name');
        if (data?.FirstName && nameDom) {
            nameDom.innerHTML = data?.FirstName + ' ' + data?.LastName || '';
        }
    }

    private updateConfirmationEmail(data: any) {
        const emailDom = document.getElementById('finance_confirmation_email');
        if (data?.Email && emailDom) {
            emailDom.innerHTML = data?.Email || '';
        }
    }

    private updateConfirmationPhone(data: any) {
        const phoneDom = document.getElementById('finance_confirmation_phone');
        if (data?.Phone && phoneDom) {
            phoneDom.innerHTML = data?.Phone || '';
        }
    }
    private updateConfirmationAddress(data: any) {
        const addressDom = document.getElementById('finance_confirmation_address');
        if (data && addressDom) {
            let addressParts = '';
            if (data.Address) {
                addressParts += data.Address;
            }
            if (data.address2) {
                addressParts += (addressParts ? ', ' : '') + data.address2;
            }
            if (data.City) {
                addressParts += (addressParts ? ', ' : '') + data.City;
            }
            if (data.State || data.ZipCode) {
                addressParts += (addressParts ? ', ' : '') + `${data.State} ${data.ZipCode}`.trim();
            }
            addressDom.innerHTML = addressParts;
        }
    }
}


const financesService = new FinancesService();

document.addEventListener('DOMContentLoaded', () => {
    const doingBusinessAs = sessionStorage.getItem('doingBusinessAs');
    if (doingBusinessAs) {
        const doingBusinessAsElement = document.querySelector('.finance-sub-heading');
        if (doingBusinessAsElement) {
            doingBusinessAsElement.classList.remove('hidden');
            doingBusinessAsElement.innerHTML = doingBusinessAs;
        }
    }
});