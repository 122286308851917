import axios from "axios"
// new changes
export const fileUploadState = {
    isUploadedToS3: false
};


const generatePresignedURL = function (filename: string) {
    const apiDomain = (location.hostname === "localhost" || window.location.hostname.indexOf("nblydev") !== -1 || window.location.hostname.indexOf("nblysbx") !== -1 || window.location.hostname.indexOf("nblytest") !== -1)
        ? process.env.JS_API_URL : "https://api.neighborly.com";
    const apiKey = (location.hostname === "localhost" || window.location.hostname.indexOf("nblydev") !== -1 || window.location.hostname.indexOf("nblysbx") !== -1 || window.location.hostname.indexOf("nblytest") !== -1)
        ? process.env.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    const digitalorchestrations = (location.hostname === "localhost" || window.location.hostname.indexOf("nblydev") !== -1 || window.location.hostname.indexOf("nblysbx") !== -1 || window.location.hostname.indexOf("nblytest") !== -1)
        ? process.env.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";
 
    return axios.get(`${apiDomain}/${digitalorchestrations}/v1/public/OnlineSchedulerFileUpload/getpresignedurl?filename=${filename}&apikey=${apiKey}`, {       
    }).then(response =>{
            return response.data.presignedURL
        }
        )
        .catch(error => Promise.reject(error));
};

function CreateGuid(){
    function _p8(s: any)
    {
        const p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
}


function makeS3Request(presignedURL:any, file:any) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      let reader = new FileReader();
      xhr.open("PUT", presignedURL);
      reader.onload = function(evt:any) {
           xhr.send(evt.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }



// import { generatePresignedURL, makeS3Request } from './your/path/to/refactored-functions';

export function s3upload(filesToUpload: any, successHandler: (fs: any) => void) {

    if (!fileUploadState.isUploadedToS3) {
        const imagesUploaded = filesToUpload;
        const promiseAllArray = [];

        if (imagesUploaded) {
                const guid = CreateGuid();
                const  File  = imagesUploaded
                const fileName = guid + '.' + File.name.split('.').pop();

                const presignedUrls = generatePresignedURL(fileName)
                    .then((presignedurl) => {
                        if (presignedurl != null) {
                          
                            return makeS3Request(presignedurl, File);
                        }
                    })
                    .catch((error) => {
                   
                    });

                promiseAllArray.push(presignedUrls);

            Promise.all(promiseAllArray)
                .then((resp) => {
                    
                    successHandler(fileName);
                })
                .catch(() => {
                   
                });
        }
    }
}