import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { extractServices } from "./filterArray";
import { AppointmentForm } from './step3';
import { brandColorCode } from "../location-search-map/config";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";


import { s3upload } from "../util/fileUploads3";
import { startLoader, stopLoader } from "../util/loader"
import { getCountryCodefromAssetpath, getWeblocationPathGroupWithDynamicConfig } from "../util/share";

let serviceData: any = []

export let addressFromInput:any = {}

export const FilesUrl: any = [null, null, null]
export let fee: any = null
export let serviceSelect = ''
export let serviceSelectCat = ''
export let serviceSelectFloors = ''
export let serviceStype: any = 'Residential'

interface MenuItem {
    id: number;
    name: string;
    serviceType: string;
}
export class FormHandlerStep2 {
    private formElement: HTMLFormElement;
    private form: any;
    private serviceTypeRadioElements: NodeListOf<HTMLInputElement>;
    private sendNoteTextarea: HTMLTextAreaElement;
    private selectServiceSelect: HTMLSelectElement;
    private imageInputs: HTMLInputElement[];
    private emailCheckbox: HTMLInputElement;
    private textCheckbox: HTMLInputElement;
    private howAboutUsSelect: HTMLSelectElement;
    private selectSubServiceSelect: HTMLSelectElement;
    private selectFloorAppliance: HTMLSelectElement;
    private showButton2: Boolean
    private onlineScheduling :boolean;

    constructor(onlineScheduling:boolean) {
        this.onlineScheduling = onlineScheduling;
        this.formElement = document.querySelector('.contact-us-section') as HTMLFormElement;
        this.showButton2 = true
        this.form = document.querySelectorAll('.contact-us-section .btn-next')
        this.serviceTypeRadioElements = document.querySelectorAll('input[name="service-type"]');
        this.sendNoteTextarea = document.getElementById('send-note') as HTMLTextAreaElement;
        this.selectServiceSelect = document.getElementById('select-service-category') as HTMLSelectElement;
        this.imageInputs = Array.from(document.querySelectorAll('input[type="file"]'));
        this.emailCheckbox = document.getElementById('default-checkbox') as HTMLInputElement;
        this.textCheckbox = document.getElementById('checked-checkbox') as HTMLInputElement;
        this.howAboutUsSelect = document.getElementById('how-about-us') as HTMLSelectElement;
        this.selectSubServiceSelect = document.getElementById('select-service') as HTMLSelectElement;
        this.selectFloorAppliance = document.getElementById('select-floor') as HTMLSelectElement;
         
        this.howAboutUsSelect?.addEventListener('change', this.hideError.bind(this, 'how-about-us'))
        this.selectServiceSelect?.addEventListener('change', this.hideError.bind(this, 'select-service-category'))
        this.sendNoteTextarea?.addEventListener('input', this.hideError.bind(this, 'send-note'))
        this.selectSubServiceSelect?.addEventListener('change', this.hideError.bind(this, 'select-service'))
        this.selectFloorAppliance?.addEventListener('change', this.hideError.bind(this, 'select-floor'))
        this.initImages()

        if (this.form) {
            this.form[1]?.addEventListener('click', this.handleSubmit.bind(this))

            if (this.howAboutUsSelect?.required || this.selectServiceSelect?.required || this.sendNoteTextarea?.required) {
                // this.form[1].disabled = true
                // this.form[1].style.opacity = '0.3'
            }
        }

        if (this.serviceTypeRadioElements && this.serviceTypeRadioElements?.length > 0) {
            this.serviceTypeRadioElements[0].checked = true;
            this.serviceTypeRadioElements.forEach((item: any) => {
                item.addEventListener('click', (e: any, i:any) => {
                    const selectService = document.getElementById('select-service-category') as HTMLSelectElement;
                    let index = this.getValue()
                    if(selectService !== null) {
                    selectService.innerHTML = '';
                    // Create and append new <option> elements
                    

                    serviceStype = index == 1 ? 'Commercial' : 'Residential'

                    const option = document.createElement('option');
                    option.value = ''; // Set the value attribute as needed
                    option.textContent = 'Select a Service Category'; // Set the option text as needed
                    selectService?.appendChild(option);
                    }
                    serviceData[index]?.category.forEach((optionData: any) => {
                        const option = document.createElement('option');
                        option.value = optionData.name; // Set the value attribute as needed
                        option.textContent = optionData.name; // Set the option text as needed
                        selectService?.appendChild(option);
                    });

                    setTimeout(()=>{
                        this.setupData.call(this);
                        this.setupData2.call(this);
                        
                       }, 1000)

                })
            })

            this.setAutoSuggested()
        }
        this.solutionAppointmentError();
        this.getMenue()
        this.setHowOknow()
        this.getFee()
        this.floorAppliance();

        const cancleBtn = document.querySelectorAll('.contact-us-section .cancel-verification')
        if (cancleBtn) {
            cancleBtn[1]?.addEventListener('click', () => {
                const formNext = document.querySelectorAll('#form-section .contact-us-section')
                const progress = document.querySelectorAll('.progressbar .progress-step')
                const progressBar: any = document.getElementById('progress')
                const form1 = formNext[1]
                const form2 = formNext[0]
                const progress1 = progress[1]
                const progress2 = progress[0]
                progressBar.style.width = "0%"
                form1.classList.remove('form-step-active')
                form2.classList.add('form-step-active')
                progress1.classList.remove('progress-step-active')
                progress2.classList.add('progress-step-active')
            })
        }

        this.hideClosetButton()

    }

    private hideClosetButton() {

        let appoinmentWrapper = document.querySelector('.select-solution-wrap');
        let appointMentCloset = window?.offersClosetAppointments;

        if (!appointMentCloset || !this.onlineScheduling) {
            appoinmentWrapper?.classList?.add('hidden');
            sessionStorage.setItem('solutionAppointment', 'false');
        }else {
            appoinmentWrapper?.classList?.remove('hidden');
            sessionStorage.setItem('solutionAppointment', 'true');
        }
    }

    private setAutoSuggested = () => {
      
            const request = {
                method: 'GET',
                url: ` https://discover.search.hereapi.com/v1/discover?q=${(document.getElementById('zip-code') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                  
                     addressFromInput = result?.items[0]
    
                    // const arr = result?.items.map((item: any) => item.title)
                    // this.setDatat(result?.items.map((item: any) => item.title))
                })
                .catch((err) => {
                });
       
    }

    setupData() {    
        document.addEventListener("click", this.closeAllSelect.bind(this));
            const customSelect:any = document.getElementById("custom-select1");

            if(customSelect !==null){

                const select = customSelect.querySelector("select") as HTMLSelectElement;
                const options = select.options;
                const selectedOption = options[select.selectedIndex];

                const selectselectedd = customSelect.querySelector('.select-selected')
                selectselectedd?.remove();
                
                const colorCode:any = document.getElementById('conceptCode') as HTMLInputElement;
                const colorCodeVal:any = brandColorCode[colorCode.value];

                const selectSelected = document.createElement("button");
                selectSelected.type="button";
                selectSelected.className = "select-selected";
                selectSelected.innerHTML = selectedOption?.innerHTML ?selectedOption?.innerHTML : '' ;
                customSelect.appendChild(selectSelected);

                const selectItems = document.createElement("div");
                selectItems.className = "select-items select-hide slim-scroll";
        
                for (let i = 0; i < options.length; i++) {
                    if (i === select.selectedIndex) continue;
        
                    const option = document.createElement("button");
                    option.innerHTML = options[i].innerHTML;
                    option.type="button";
        
                    option.addEventListener("click", () => { // Use an arrow function here
                        select.selectedIndex = i;
                        selectSelected.innerHTML = options[i].innerHTML;

                        serviceSelectCat = options[i].innerHTML
                        selectSelected.style.backgroundColor = colorCodeVal;
                        selectSelected.style.color = "white";
                        this.setupServiceDropdown(selectSelected);
                        this.setupData2.call(this)
                        this.closeAllSelect.call(this); // Remove unnecessary arguments
                    });
        
                    selectItems.appendChild(option);
                }
                
                this.setupServiceDropdown(selectSelected);

                customSelect.appendChild(selectItems);
        
                selectSelected.addEventListener("click", (e) => { // Use an arrow function here
                    e.stopPropagation();
                    this.closeAllSelect();
                    
                    selectItems.classList.toggle("select-hide");
                    selectSelected.classList.toggle("select-arrow-active");
                    this.escapeServiceCategory(selectItems,selectSelected);
                });
            // });
            }else {
                this.setupServiceDropdown(null);
            }
    }

   

    setupData2() {
        document.addEventListener("click", this.closeAllSelect.bind(this));
        const customSelect:any = document.getElementById("custom-select2");
        if(customSelect != null) {
        // customSelects.forEach((customSelect) => {
            const select = customSelect.querySelector("select") as HTMLSelectElement;
            const selectselected = customSelect.querySelector('.select-selected')
              selectselected?.remove()
            const options = select.options;
            const selectedOption = options[select.selectedIndex];

   
            const colorCode:any = document.getElementById('conceptCode') as HTMLInputElement;
            const colorCodeVal:any = brandColorCode[colorCode.value];

            const selectSelected = document.createElement("button");
            selectSelected.className = "select-selected";
            selectSelected.type="button";
            selectSelected.innerHTML = selectedOption?.innerHTML ?selectedOption?.innerHTML : '' ;
            
            customSelect.appendChild(selectSelected);
            const selectItems = document.createElement("div");
            selectItems.className = "select-items select-hide slim-scroll";

            if(options.length > 1 || this.selectServiceSelect === null ){
                const data = document.querySelector('.select-service')
                data?.classList.remove('select-service')
            }
    
            for (let i = 0; i < options.length; i++) {
                if (i === select.selectedIndex) continue;
    
                const option = document.createElement("button");
                option.innerHTML = options[i].innerHTML;
                option.type="button";
                option.addEventListener("click", () => { // Use an arrow function here
                    select.selectedIndex = i;
                    selectSelected.innerHTML = options[i].innerHTML;
                    serviceSelect = options[i].innerHTML;
                    selectSelected.style.backgroundColor = colorCodeVal;
                    selectSelected.style.color = "white";
                    this.closeAllSelect(); // Remove unnecessary arguments
                });
    
                selectItems.appendChild(option);
            }
    
            customSelect.appendChild(selectItems);
    
            selectSelected.addEventListener("click", (e) => { // Use an arrow function here
                e.stopPropagation();
                this.closeAllSelect();
            
                selectItems.classList.toggle("select-hide");
                selectSelected.classList.toggle("select-arrow-active");
                this.escapeServiceCategory(selectItems,selectSelected);
            });
        // });
        }
    }
    
    floorAppliance() {
        document.addEventListener("click", this.closeAllSelect.bind(this));
        const customSelect = document.getElementById("custom-select3");
        const hideError = document.getElementById('select-floor-error-msg');
        if (customSelect) {
            const select = customSelect.querySelector("select") as HTMLSelectElement;
            const selectselected = customSelect.querySelector('.select-selected');
            if (select && selectselected) {
                selectselected.remove();
                const options = select.options;
                const selectedOption = options[select.selectedIndex];
    
                const colorCode = document.getElementById('conceptCode') as HTMLInputElement;
                const colorCodeVal = brandColorCode[colorCode.value];
    
                const selectSelected = document.createElement("button");
                selectSelected.className = "select-selected";
                selectSelected.type = "button";
                selectSelected.innerHTML = selectedOption ? selectedOption.innerHTML : '';
                customSelect.appendChild(selectSelected);
    
                const selectItems = document.createElement("div");
                selectItems.className = "select-items select-hide slim-scroll";
    
                if ((options.length > 1) || !this.selectServiceSelect) {
                    const data = document.querySelector('.select-floor');
                    if (data) {
                        data.classList.remove('select-floor');
                    }
                }
    
                for (let i = 0; i < options.length; i++) {
                    if (i === select.selectedIndex) continue;
    
                    const option = document.createElement("button");
                    option.innerHTML = options[i].innerHTML;
                    option.type = "button";
                    option.addEventListener("click", () => {
                        hideError?.classList.add('hidden');
                        select.selectedIndex = i;
                        selectSelected.innerHTML = options[i].innerHTML;
                        serviceSelectFloors = options[i].innerHTML;
                        selectSelected.style.backgroundColor = colorCodeVal;
                        selectSelected.style.color = "white";
                        this.closeAllSelect();
                    });
    
                    selectItems.appendChild(option);
                }
    
                customSelect.appendChild(selectItems);
    
                selectSelected.addEventListener("click", (e) => {
                    e.stopPropagation();
                    this.closeAllSelect();
                    selectItems.classList.toggle("select-hide");
                    selectSelected.classList.toggle("select-arrow-active");
                    this.escapeServiceCategory(selectItems, selectSelected);
                });
            }
        }
    }
     
    closeAllSelect() { // Remove unnecessary 'e' parameter
        const selectItems = document.querySelectorAll(".select-items.slim-scroll");
        const selectSelected = document.querySelectorAll(".select-selected");
    
        selectItems.forEach((item) => {
            item.classList.add("select-hide");
        });
    
        selectSelected.forEach((selected) => {
            selected.classList.remove("select-arrow-active");
        });
    }
    




    initImages() {
        const containerImages = document.querySelector('.scheduler-main')

        if (containerImages) {
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach((fileInput, index) => {
                fileInput.addEventListener('change', () => {
                    this.showFilePreview(fileInput, index)
                }
                );
            });

            const closeIcons = document.querySelectorAll('.icon-close')

            closeIcons.forEach((close, index) => {
                close.addEventListener('click', this.clearPreview.bind(this, index));
            });
        }

    }

    getFee() {
        const dynamic_url = `${apiConfig.franchiseWebLocationPostalCodeFees}/${sessionStorage.getItem('franchiseWebLocationId')}/${(document.getElementById('zip-code') as HTMLInputElement).value}?apikey=${process.env.JS_API_KEY}`
        const request = {
            method: 'GET',
            url: dynamic_url,
        };

       

        apiRequest(request)
            .then((response: any) => {
                fee = response.find((x: any) => x.feeName == "Inspection Fee")
                const container = document.querySelector('.inspection-fee')
                if (container && fee?.fee)
                    container.innerHTML = fee ? `<strong> $${fee?.fee}</strong>` : ''


            }).catch((err) => {

            });

    }

    setHowOknow() {

        let dynamic_url = `${apiConfig.howToKnow}?apikey=${process.env.JS_API_KEY}`;
        const franchiseId = sessionStorage.getItem('franchiseId'); 
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value?.toLocaleLowerCase();

        if(conceptCode === 'dvw'){
            dynamic_url = dynamic_url+`&franchiseId=${franchiseId}`
        }
        const request = {
            method: 'GET',
            url: dynamic_url,
        };

        const selectHowOnow = document.getElementById('how-about-us') as HTMLSelectElement;

        if (!this.howAboutUsSelect?.value || this.howAboutUsSelect?.value == 'Select a referal method')
            apiRequest(request)
                .then((response: any) => {
                    const options = response;
                    // Clear existing options
                    selectHowOnow.innerHTML = '';
                    // Create and append new <option> elements
                    const option = document.createElement('option');
                    option.value = ''; // Set the value attribute as needed
                    option.textContent = 'Select a Referral Method'; // Set the option text as needed
                    selectHowOnow.appendChild(option);

                    options.forEach((optionData: any) => {
                        const option = document.createElement('option');
                        option.value = optionData.leadSourceName; // Set the value attribute as needed
                        option.textContent = optionData.leadSourceName; // Set the option text as needed
                        option.setAttribute('data-sync-generic-lead-source-id', optionData.syncGenericLeadSourceId); 
                        if(conceptCode==='dvw' && optionData.referralTypeId){
                            option.setAttribute('data-referral-type-id', optionData.referralTypeId); 
                        }
                        selectHowOnow.appendChild(option);
                    });

                }).catch(() => {
                    
                    if(conceptCode === 'dvw'){
                        document.getElementById('modal-zipcode-error')?.classList.add('hidden');
                        document.getElementById('error-modal-id')?.click();
                        document.getElementById('modal-appoinment-slot')?.classList.remove('hidden');
                        document.getElementById('error-modal')?.classList.remove('hidden');
                        
                    }

                });
    }

    private getValue() {
        const checkedRadio = document.querySelector('input[name="service-type"]:checked') as HTMLInputElement;

        // Check if a radio input is checked
        if (checkedRadio) {
            // Get the label associated with the checked radio input
            const labelForCheckedRadio = document.querySelector(`label[for="${checkedRadio.id}"]`) as HTMLLabelElement;

            // Get the text content of the label
            const labelText = labelForCheckedRadio?.textContent;

            // Use labelText as needed
            return labelText == 'Commercial' ? 1 : 0
        } else {
            return 0
        }
    }

    private handleSubmit(event: Event): void {
        event.preventDefault();


        // Perform client-side validation here
        if (this.isFormValid()) {

            if (this.form[0].innerHTML == 'Next' || this.form[1].innerHTML == 'Next') {
               
                window.scrollTo({top: 0});
      
                let appointMentCloset = window?.offersClosetAppointments; 

                if(this.onlineScheduling && appointMentCloset){
                let solutionAppointment = (document.querySelector("input[type='radio'][name=solution-type]")as HTMLInputElement);
                if(solutionAppointment){
                    sessionStorage.setItem('solutionAppointment', 'true');
                    new AppointmentForm(this.onlineScheduling);
                } 
              }
            }

            if (this.form[1].innerHTML == 'Submit') {
                const onlineScheduling:any = false;
                new AppointmentForm(onlineScheduling).handleSubmit(event, false);
                
            }
            else {

                const formNext = document.querySelectorAll('#form-section .contact-us-section')
                const progress = document.querySelectorAll('.progressbar .progress-step')
                const progressBar: any = document.getElementById('progress')
                const form1 = formNext[1]
                const form2 = formNext[2]
                const progress1 = progress[1]
                const progress2 = progress[2]
                progressBar.style.width = "100%"
                form1.classList.remove('form-step-active')
                form2.classList.add('form-step-active')
                progress1.classList.remove('progress-step-active')
                progress1.classList.add('progress-step-confirmed')
                progress2.classList.add('progress-step-active')


                window.scrollTo(0, 10);


            }
        }
        // Construct form data to send to the API
        const formData = new FormData(this.formElement);

    }


    hideError(id: any) {
        const field: any = document.getElementById(id);
        let fieldError: any = document.getElementById(`${id}-error-msg`);

        if(field.id == 'send-note' && field.value == '') {
            fieldError.classList.remove('hidden');
            field.classList.add('invalid-field');
            return;
        }
        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')

        setTimeout(() => { this.showSubmitButton.call(this) }, 300)

    }

    showSubmitButton() {

        // if((this.howAboutUsSelect.required ?   this.howAboutUsSelect.value : true) && (this.selectServiceSelect.required  ? this.selectServiceSelect.value : true) && (this.sendNoteTextarea.required ? this.sendNoteTextarea.value : true)){
        //    this.form[1].disabled = false
        //    this.form[1].style.opacity = '1'
        // }
        // else
        // {
        //    this.form[1].disabled = true
        //    this.form[1].style.opacity = '0.3'

        // }
    }


    async getMenue() {
        let dynamic_url = getWeblocationPathGroupWithDynamicConfig();
        const selectService = document.getElementById('select-service-category') as HTMLSelectElement;
        if (!this.selectServiceSelect?.value || this.selectServiceSelect?.value == 'Select a category type'){
            try{
                const cachedResponse = await getCachedAPIResponse(dynamic_url, apiCaching.SiteConfig, false);
                if (cachedResponse) {
                    const data = cachedResponse.serviceMenu;
                        const options = data["country"][0].brand[0].option;
                        serviceData = options;
    
                        if(selectService !==null){
    
                            // Clear existing options
                            selectService.innerHTML = '';
                            // Create and append new <option> elements
                            const option = document.createElement('option');
                            option.value = ''; // Set the value attribute as needed
                            option.textContent = 'Select a Service Category'; // Set the option text as needed
                            selectService.appendChild(option);
                            
                            options[0]?.category?.forEach((optionData: any) => {
                                const option = document.createElement('option');
                                option.value = optionData.name; // Set the value attribute as needed
                                option.textContent = optionData.name; // Set the option text as needed
                                selectService.appendChild(option);
                            });
    
                        }
                        setTimeout(()=>{
                            this.setupData.call(this)
                            this.setupData2.call(this)
                        }, 1000)
                }
            }
            catch(error){
                console.log("Error in getMenue function", error)
            }
        }
        
    }
    
    solutionAppointmentError(): void {
        const shelfError = document.getElementById('shelf-solution-radio') as HTMLInputElement;
        const closetError = document.getElementById('closet-solution-radio') as HTMLInputElement;
        const bothError = document.getElementById('both-solution-radio') as HTMLInputElement;
        const radioButtons = [shelfError, closetError, bothError];
        radioButtons.forEach(element => {
            element?.addEventListener('click', () => {
                this.hideErrorMessage();
            });
        });
    }
    
    hideErrorMessage() {
        const errorMsg = document.getElementById('shelf-solution-radio-error-msg') as HTMLElement;
        if (errorMsg) {
            errorMsg.classList.add('hidden');
        }
    }
    private isFormValid(): boolean {
        const shelfError = document.getElementById('shelf-solution-radio') as HTMLInputElement;
        const closetError = document.getElementById('closet-solution-radio') as HTMLInputElement;
        const bothError = document.getElementById('both-solution-radio') as HTMLInputElement;

        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};
        
        const allNull = !shelfError && !closetError && !bothError;
        let appointMentCloset = window.offersClosetAppointments; 

        if (!allNull && appointMentCloset && this.onlineScheduling) {
            
            const isShelfValid = !shelfError || (shelfError.required && !shelfError.checked);
            const isClosetValid = !closetError || (closetError.required && !closetError.checked);
            const isBothValid = !bothError || (bothError.required && !bothError.checked);
        
            if (isShelfValid && isClosetValid && isBothValid) {
                errors['shelf-solution-radio'] = '';
            }
        }
        if (this.sendNoteTextarea?.required && this.sendNoteTextarea?.value === '') {
            errors['send-note'] = '';
        }

        if (this.selectServiceSelect?.required && this.selectServiceSelect?.value === '') {
            errors['select-service-category'] = '';
        }

        if (this.selectSubServiceSelect?.required && this.selectSubServiceSelect?.value === '') {
            errors['select-service'] = '';
        }
        
        if (this.selectFloorAppliance?.required && this.selectFloorAppliance?.value === 'Select floor') {
            errors['select-floor'] = '';
        }

        if (this.howAboutUsSelect?.required && this.howAboutUsSelect?.value === '') {
            errors['how-about-us'] = '';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            let fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError && fieldError.classList?.contains('hidden'))
                    fieldError.classList.remove('hidden')
                // field.parentNode.appendChild(errorElement);
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;    
    }


    private resetForm(): void {
        this.formElement.reset();
    }




    private getSelectedFiles(): File[] {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        const selectedFiles: File[] = [];
        fileInputs.forEach((input: any) => {
            if (input.files && input.files.length > 0) {
                selectedFiles.push(input.files[0]);
            }
        });
        return selectedFiles;
    }

    private clearPreview(index: any): void {
        const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
        const uploadData: any = document?.querySelectorAll('.upload-data')[index];
        previewContainer.src = null
        const fileInputs: any = document.querySelectorAll('input[type="file"]')[index] as HTMLInputElement
        fileInputs.value = null
        previewContainer.removeAttribute('style')
        uploadData.classList.remove('active');
    }


    private showFilePreview(fileInput: any, index: number): void {
        const file = fileInput.files && fileInput.files[0];
        // FilesUrl[index] =    URL.createObjectURL(file)

        s3upload(file, (fs) => {
            FilesUrl[index] = fs

        })

        const fileName = file.name;
        const fileExt = fileName.split('.')[1]?.trim();
        const imgFiles = ["png", "svg", "jpg", "jpeg"].includes(fileExt);
        const pdfFiles = fileExt === "pdf";
        const excelFiles = fileExt === "xlsx";
        const docFiles = ["docx", "doc"].includes(fileExt);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
                const uploadData: any = document?.querySelectorAll('.upload-data')[index];
                if (previewContainer) {
                    if (imgFiles) {
                        previewContainer.src = reader.result as string;
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (pdfFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/pdf-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (excelFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/excel-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (docFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/doc-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    }

                }
            };
            reader.readAsDataURL(file);
        }
    }

    private escapeServiceCategory(selectItems: HTMLDivElement,selectSelected: HTMLButtonElement){
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
           selectItems.classList.add("select-hide");
           selectSelected.classList.remove("select-arrow-active");
            }
          })
    }

     flattenMenu(menu:any) {
        return menu.reduce((result:any, item:any) => {
            result.push(item);
    
            if (item.menu && Array.isArray(item.menu)) {
                result.push(...this.flattenMenu(item.menu));
            }
    
            return result;
        }, []);
    }

    public setupServiceDropdown(selectSelected: HTMLButtonElement | null) {

        const customSelect2 = document.getElementById("custom-select2");
            if(customSelect2 != null) {
            const indexx = this.getValue();
            const valueSelected = serviceData[indexx]?.category?.find((x: any) => x.name == selectSelected?.innerHTML);

            let servicess = extractServices(valueSelected);

            if (selectSelected === null) {

                const flattenedMenu = serviceData[indexx]?.category?.flatMap((item: {menu:any}) => {
                    const menu = item?.menu || [];
                    return this.flattenMenu(menu);
                });
                if (flattenedMenu && Array.isArray(flattenedMenu)) {
                    const sortedMenu: MenuItem[] = flattenedMenu.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
                    const uniqueMenu: MenuItem[] = sortedMenu.filter((value, index, self) =>
                        index === self.findIndex((item) => item.name === value.name)
                    );
                    servicess = uniqueMenu;
                } 
            
            }

            if (!valueSelected) {

                if (customSelect2) {
                    const parentElement = customSelect2.parentElement;
                    if (parentElement) {
                        parentElement.classList.add('select-service');
                    }
                }
            }
            const selectService = document.getElementById('select-service') as HTMLSelectElement;

            if (selectService !== null) {

                selectService.innerHTML = '';
                const option = document.createElement('option');
                option.value = '';
                option.textContent = 'Select a Service';
                selectService.appendChild(option);

            }
            servicess?.forEach((optionData: any) => {
                const option = document.createElement('option');
                option.value = optionData.name;
                option.textContent = optionData.name;
                selectService.appendChild(option);
            });
        }
    }

}


// Initialize the form handler

