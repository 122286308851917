import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { ScreenType } from "../location-search-map/models/ScreenType";
import { reportScreenSize } from "../location-search-map/reportScreenSize";
import { replaceLocalLogoLink } from "./replaceLocalLogoLink";
import { getBrandJSON } from "../util/locationBasedBrandJson";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";


export function shareOnFacebook(imageUrl:any, caption:any) {
    // Construct the Facebook sharing URL
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}&quote=${encodeURIComponent(caption)}`;

    // Open a new window or tab to share the URL on Facebook
    window.open(shareUrl, '_blank');
}

export function shareOnTwitter(text:any, url:any) {
    const tweetText = encodeURIComponent(text);
    const tweetURL = encodeURIComponent(url);
    const shareURL = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetURL}`;

    // Open the tweet URL in a new window
    window.open(shareURL, '_blank');
}


export function shareOnLinkedIn(imageURL:any) {
    // URL of the image you want to share


    // URL for sharing on LinkedIn
    const shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(imageURL)}`;

    // Open a new window or tab to share the image on LinkedIn
    window.open(shareURL, '_blank');
}

export function shareOnPinterest(imageUrl: any, description:any) {
    // Base Pinterest share URL
    const pinterestShareUrl = 'https://www.pinterest.com/pin/create/button/';
  
    // Parameters
    const urlParams = new URLSearchParams();
    urlParams.append('url', imageUrl); // URL of the image to share
    urlParams.append('description', description);
  
    // Final Pinterest share URL
    const finalUrl = pinterestShareUrl + '?' + urlParams.toString();
  
    // Open the Pinterest share URL in a new window or tab
    window.open(finalUrl, '_blank');
  }
  export function insertAfterUrl(){
    let refresh:any
    if(window.location.pathname.substr(-1) != '/' ){
        refresh= window.location.protocol + "//" + window.location.host + window.location.pathname + '/#thank-you';
    }else {
        refresh= window.location.protocol + "//" + window.location.host + window.location.pathname + '#thank-you';
    }
        
    window.history.pushState({ path: refresh }, '', refresh);
}

export async function getFranchiseWebLocationIdDetails(zipCode:any): Promise<number | string> {
    try {

        const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue
        .replace("sAddressParamValue", encodeURIComponent(zipCode))
        .replace(/([?&])RequireAddressOnPartialPostalCode=[^&]*(&|$)/, '$1');
       
        const request = {
            method: 'GET',
            url: apiUrl
        };

        const locateLocationRes: any[] = await apiRequest(request);

        if (Array.isArray(locateLocationRes) && locateLocationRes.length > 0) {
            const firstLocation = locateLocationRes[0];
            return firstLocation.hasOwnProperty('franchiseWebLocationId') ? firstLocation.franchiseWebLocationId : "";
        }

        return "";
    } catch (error) {
        console.error('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        return "";
    }
}

export async function getBrandDetailsFromBrandJson(brandName:string):  Promise<any> {
    try {
        const brandJson = getBrandJSON();
        if(brandJson === 'Error validating Location'){
            console.error("Error Determining BrandJSON path for given location");
            return [];
        }
        else{
            const brandData = localStorage.getItem("brandDetails");
            if(brandData !== "undefined" && brandData !== null){
                let parsedBrandData = JSON.parse(brandData);
                if(parsedBrandData?.code === brandName){
                    return parsedBrandData;
                }
            }
            
            const origin = window.location.origin;
        
            const request = {
                method: 'GET',
                url: `${origin}/${brandJson}`
            };

            const result :any = await apiRequest(request);
            const brandDetails = result.find((item: any) => item.code === brandName);

            localStorage.setItem("brandDetails", JSON.stringify(brandDetails));
            return brandDetails;
        }
        

    } catch (error) {
        console.error('Error in fetching getBrandDetailsFromBrandJson API data:', error);
        return [];
    }
}

export function getCountryCodefromAssetpath():string{
    let countryCode:any = document.getElementById('countryCode') as HTMLInputElement;
    countryCode = countryCode?.value.toLocaleLowerCase();
    let assetPath:any = document.getElementById('assetPath') as HTMLInputElement;
    assetPath = assetPath?.value.toLocaleLowerCase();
    const countryCodeFromAsset:string = assetPath?.includes('blogs') ? assetPath?.split('/')[4] : assetPath?.split('/')[5];
    if(countryCode === countryCodeFromAsset){
        return countryCode;
    }
    return countryCodeFromAsset;
}
export function getCampaignName(){
    let hrefUrl = window.location.href;
    let campaignName=''
        if (hrefUrl.indexOf('?') > -1)
        {
            let urlPath :any = new URL(hrefUrl);
            let urlAfterQueryStr = urlPath?.search?.replace('?', '');
            campaignName = urlAfterQueryStr;
            sessionStorage.setItem('campaignName', campaignName);

        } else
        {
            campaignName = 'N/A';
        }

        return campaignName;
    }

    export async function commonStates(countryCode:string):  Promise<any> {
        try {
            const stateCaUs = localStorage.getItem(countryCode);
            if(stateCaUs){
                return JSON.parse(stateCaUs);
            }
            const apiUrl = apiConfig.stateApi + countryCode + "?apikey=" + process.env.JS_API_KEY;
            const request = {
                method: 'GET',
                url: apiUrl
            };
            const result :any = await apiRequest(request);
            localStorage.setItem(countryCode, JSON.stringify(result)); 
            return result;
    
        } catch (error) {
            console.error('Error in fetching state API data:', error);
            return [];
        }
    }

    export async function localSearch(){
        const dbaName = document.getElementById('full_dbaName') as HTMLInputElement;
        const country_code = getCountryCodefromAssetpath();
        const url = window.location.origin;   
        try{
            const apiUrl = `${url}/${country_code}/en-${country_code}/_assets/brand/${dbaName?.value}/js/data/dynamic_menu.json`;
            const cachedResponse = await getCachedAPIResponse(apiUrl, apiCaching.DynamicMenu, false);
            if (cachedResponse) {
                const data = cachedResponse.serviceMenu;
                const options = data["country"][0]?.brand[0]?.webLocation[0]?.option;
                const brandMenu = options;
                sessionStorage.setItem('localFilterService', JSON.stringify(brandMenu));
            }
        }
        catch(error){
            console.error('Error in fetching state API data:', error);
        }
    }
    
    export function getFooterLink(){
        try { 
        let data:any = [];
        let dynamicMenuURLResponse: any = {};
            dynamicMenuURLResponse = localStorage.getItem('dynamicMenuURLResponse');
            dynamicMenuURLResponse = JSON.parse(dynamicMenuURLResponse);
            const dynamicServicesMenu:any = dynamicMenuURLResponse?.serviceMenu?.country[0]?.brand[0]?.option;
            dynamicServicesMenu?.forEach((item: any) => {
                data.push({
                    name: item?.name,
                    link: window.location.origin + item?.link

                })
            })
            return data
        } catch(error){
            return [];
        }
    }

    export async function checkOSFlowPathAndReturnBoolean() {

        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        
        if (conceptCodeElement) {

            const conceptCode = conceptCodeElement.value;
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);
            const extractBrandOSFlowPath = brandData.request_button_link_type ? brandData.request_button_link_type.replace(/\//g, "") : "";
            const isApp = window.location.pathname.includes(extractBrandOSFlowPath);
    
            return isApp;
        }
    
        // Handle the case when conceptCodeElement is not found
        return false;
    }

    export async function updateHeaderContent(locationDoingBusinessAs: any) {
        const headerAddress = document.querySelector(".local-location .address") as HTMLElement;
        const headerDescription = document.querySelector(".local-location .description") as HTMLElement;
        const headerLogoLink = document.querySelector(".brand-header-local .brand-logo-wrapper") as HTMLElement;
    
        let trailingSlash = "";
        let hostMapWebLocation = '';

        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;

        if(conceptCode) {
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);
            if(brandData?.includeTrailingSlash) {
                trailingSlash = "/";
            }

            if(brandData?.weblocation_path_group){
                hostMapWebLocation = brandData.weblocation_path_group;
            }
        }
        let dba = localStorage.getItem('dbaName') ?? '';
        let dbaName = hostMapWebLocation ? `${hostMapWebLocation}/${dba}`: dba;
    
        updateHeaderElements(locationDoingBusinessAs, headerAddress, headerDescription, headerLogoLink, trailingSlash,dbaName);
        updateLocalLocationForDesktop();
        updateFooterBrandLogo();
        updateLocalLocationForDevice(trailingSlash,dbaName);
    }
    
    function updateHeaderElements(
        locationDoingBusinessAs: string,
        headerAddress: HTMLElement,
        headerDescription: HTMLElement,
        headerLogoLink: HTMLElement,
        trailingSlash: string,
        dbaName:any
    ) {

        if(headerAddress) {
            headerAddress.innerText = locationDoingBusinessAs;
        }
    
        if(headerDescription && headerDescription.innerText === "") {
            headerDescription.innerText = "Locally Owned and Operated";
        }
    
        if(headerLogoLink) {
            headerLogoLink.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
        }
    }
    
    function updateLocalLocationForDesktop() {
        const firstBrandHeaderLocal = document.querySelector(".brand-header-local") as HTMLElement;
        if(firstBrandHeaderLocal) {
            firstBrandHeaderLocal.style.display = ""; // Removes the display attribute
        }
    
        const findLocalLink = document.querySelector(".brand-header-local .find-local") as HTMLElement;
        if(findLocalLink) {
            const textNode = findLocalLink.childNodes[1];
            if (textNode) {
                textNode.textContent = "Change Location";
            }
        }
    }
    
    function updateFooterBrandLogo() {
        const FooterBrandLogo = document.querySelector('.brand-footer .fb-des-link') as HTMLElement;
        if(FooterBrandLogo) {
            replaceLocalLogoLink(FooterBrandLogo);
        }
    }
    
    function updateLocalLocationForDevice(trailingSlash: string,dbaName:any) {
        const isDesktop = reportScreenSize() === ScreenType.Desktop;
        if(!isDesktop) {
            const headerMobileDeviceLogoLink = document.querySelector(".sidenav-brand-logo") as HTMLElement;
            if(headerMobileDeviceLogoLink) {
                headerMobileDeviceLogoLink.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
            }
            const brandLogoMobileDevice = document.querySelector(".brand-logo-mobile") as HTMLElement;
            if(brandLogoMobileDevice) {
                brandLogoMobileDevice.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
            }
        }
    }

    export function hmsProgressBarUpdate(progressWidth: string,processLength: number){
        const progressBarLine = document.querySelector(".progressbar #progress") as HTMLElement;
        const progresBarItems = document.querySelectorAll(".progressbar .progress-step");
        if(progresBarItems && progressBarLine){
            progressBarLine.style.width = progressWidth;
            progresBarItems.forEach((el, index)=>{
                el.classList.remove("prograssbar-length", "progress-step-confirmed");
                el.classList.remove("prograssbar-length", "progress-step-active");
            })
            
            progresBarItems.forEach((el, index)=>{
                if(index<processLength)
                    el.classList.add("prograssbar-length", "progress-step-confirmed");
                else if(index===(processLength))
                    el.classList.add("prograssbar-length", "progress-step-active");
            })
            
        }
    }

    export function returnToHomePage(){
        let indexUrl = window.location.href;
        const fullUrl = indexUrl.split("/").slice(0, 3).join("/");
        window.location.href = `${fullUrl}/`;
    }
    export function storeBookingApiPayload(payload:any,response:any){
        try{
        const formdata = JSON.stringify(payload);
        sessionStorage.setItem('rl-post-data',formdata);
        let apiData = '{ "Result": '+ JSON.stringify(response) +', "Form data": '+ formdata +', "Response message":"SUCCESS" }';
        sessionStorage.setItem('NEI-API data',apiData);
        } catch(error) {
            console.log("Error while storing the apipayload", error)
        }
    }

    export function removeAllEventListeners(selector: any) {
        const element = document.querySelector(selector);
        if (element) {
            const newElement = element.cloneNode(true);
            element.parentNode.replaceChild(newElement, element);
            return newElement;
        }
        return null;
    }

   export function getWeblocationPathGroupWithSiteConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandData =  localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null;
    const { weblocation_path_group: hostMapWebLocation, multiple_domain: multiDomain } = brandData || {};
    let pathSegments = ['config-files', '_site-config.json'];

    if (dbaName) {
        pathSegments.unshift(dbaName);

        if (hostMapWebLocation) {
            pathSegments.unshift(hostMapWebLocation);
        }
    }

    return `${url}/${pathSegments.join('/')}`;
}

export function getWeblocationPathGroupWithDynamicConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandName = (document.getElementById('brandName') as HTMLInputElement)?.value;
    const country_code = getCountryCodefromAssetpath();

    let pathSegments = [country_code, `en-${country_code}`];

    if (dbaName) {
        pathSegments.push('_assets', 'brand', dbaName, 'js', 'data', 'dynamic_menu.json');
    } else {
        pathSegments.push(brandName, '_assets', 'js', 'data', 'dynamic_menu.json');
    }

    return `${url}/${pathSegments.join('/')}`;
}

export function getWeblocationPathGroupWithFooterConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandData = localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null; 
    const { weblocation_path_group: hostMapWebLocation, multiple_domain: multiDomain } = brandData || {};

    // Default URL setup
    let pathSegments = ['config-files', '_footer-config'];

    if (dbaName) {
        pathSegments.unshift(dbaName);

        if (hostMapWebLocation) {
            pathSegments.unshift(hostMapWebLocation);
        }
    } 

    return `${url}/${pathSegments.join('/')}`;
}

export async function getFranchiseWebLocationId(zipCode:any): Promise<number | string> {
    try {

        const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue
        .replace("sAddressParamValue", encodeURIComponent(zipCode))
        .replace(/([?&])RequireAddressOnPartialPostalCode=[^&]*(&|$)/, '$1');
       
        const request = {
            method: 'GET',
            url: apiUrl
        };

        const locateLocationRes: any[] = await apiRequest(request);

        if (Array.isArray(locateLocationRes) && locateLocationRes.length > 0) {
            const firstLocation = locateLocationRes[0];
            return firstLocation
        }

        return "";
    } catch (error) {
        console.error('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        return "";
    }
}

   export function checkDomain() {
    const url = window.location.origin;
    //for local enviroments
    if(url.includes('localhost')){
        const assetPathElement = document.getElementById("assetPath")as HTMLInputElement;
        if(assetPathElement){
            const assetPathValue = assetPathElement.value;
            if(assetPathValue.includes("/US/"))
                return 'us';
            else if(assetPathValue.includes("/CA/"))
                return 'ca';
        }
        return "Error validating Location"
    }
    //Code for lower enviroments
    else if (url.includes('nblysbx') || url.includes('nblydev') || url.includes('nblytest')){
        try{
            if (url.includes('-ca-')){
                return 'ca';
            }
            else{
                return 'us';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
    //Code for production enviroment
    else{
        try{
            const lastDotIndex = url.lastIndexOf('.'); 
            const domainExtension = url.substring(lastDotIndex + 1);
            if(domainExtension === "ca"){
                return 'ca';
            }
            else{
                return 'us';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
    }

    export function capitalizeString(str:string) {
        if (!str) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      }